import * as urls from "utils/contants/urls";
import theme from "../../../../../components/shared/Theme.js";
import {
  Label,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { Creators as messageAction } from "store/ducks/messages";
import { Col, Row , Button} from "react-bootstrap";
import { Body } from "./ManagerCandidatesContractStyle.js";
import InputMask from "react-input-mask";

import {
  ButtonDefault,
  TextField,
  DatePicker,
  SelectInput,
  RadioInput,
} from "components/shared/CustomInputs";

import { FormattedMessage } from "react-intl";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Creators as contractsAction } from "store/ducks/contracts";
import { Creators as otherPayments } from "store/ducks/otherPayments";
import { required } from "../../../../../helpers/validations.js";
import { TipoPagamento } from "../../../../../utils/contants/enums";
import { TiposDeUser } from "../../../../../utils/contants/enums";

const ManagerPaymentsContract = ({
  filesCandidateSelected,
  isLoading,
  location,
  messagePublication,
  requestTypeContracts,
  redirectToContracts,
  requestAdtive,
  createPayments,
}) => {
  const [formErros, setFormErros] = useState([]);
  const [formValidators, setFormValidators] = useState();
  const [submited, setSubmited] = useState(false);
  const [formValues, setFormValues] = useState({
    cpf: "",
    mesCompetencia: "",
    motivo: "",
    valor: "",
  });
  const [cadastroPessoaFisica, setCadastroPessoaFisica] = useState(true);

  useEffect(() => {
    isLoading = false;
    requestTypeContracts();
  }, []);

  useEffect(() => {
    if (formValues.kitContratos) requestAdtive({ id: formValues.kitContratos });
  }, [formValues.kitContratos]);

  const includeNewContract = () => {
    createPayments({
      cpf: formValues.cpf,
      mesCompetencia: formValues.mesCompetencia,
      motivo: formValues.motivo,
      valor: formValues.valor,
    });
  };

  const formatCurrency = (value) => {
    const numericValue = parseFloat(value.replace(/[^0-9]/g, "")) / 100;
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numericValue);
  };
  const handleChangeCurrency = (e, fieldName) => {
    let value = e.target.value.replace(/[^0-9]/g, "");
    if (value) {
      value = formatCurrency(value);
    }

    setFormValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };
  const LimparCampos = () => {
    setFormValues({
      cpf: "",
      mesCompetencia: "",
      motivo: "",
      valor: "",
    });
  };


  const renderFilesCandidatesTable = (items) => {
    return (
      <div>
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead ta="left" style={{ width: "50%" }}>
                <LabelHeader>Documento</LabelHeader>
              </TableHead>
              <TableHead ta="center">
                <LabelHeader>Detalhes</LabelHeader>
              </TableHead>
              <TableHead ta="center">
                <LabelHeader>Status do processo</LabelHeader>
              </TableHead>
            </TableRow>
            {items && renderTableFilesItems(items)}
          </TableBody>
        </Table>
      </div>
    );
  };
  const renderTableFilesItems = (items) =>
    items.map((log) => (
      <TableRow>
        <TableColumn ta="left">
          <Label>{log.cpf}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{log.descricao}</Label>
        </TableColumn>
        <TableColumn ta="center">
          <Label>{log.status}</Label>
        </TableColumn>
      </TableRow>
    ));

  if (isLoading) return <Load isLoading={isLoading} />;
  return (
    <React.Fragment>
      <h3 style={{ color: theme.colors.defaultColor }}>
        Cadastrar Demais Pagamentos
      </h3>

      <Body>
        <Row>
          <Col md="4">
            <ButtonDefault
              onClick={() => redirectToContracts()}
              width="230px"
              mr="10px"
              name={"Voltar"}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            md={6}
            style={{ display: "flex", flexDirection: "row", gap: "10px" }}
          >
            {TiposDeUser?.map((option, index) => {
              return (
                <RadioInput
                  label={option.label}
                  value={option.value || ""}
                  selectedOption={cadastroPessoaFisica}
                  checked={cadastroPessoaFisica === option.value}
                  onChange={() => setCadastroPessoaFisica(option.value)}
                  key={index}
                  mb={4}
                  colCheckBox={3}
                  colLabel={9}s
                />
              );
            })}
          </Col>
        </Row>
        <br />
        <Row>
          <Col
            md="3"
            style={{
              height: "130px",
            }}
          >
            <h3 style={{ color: theme.colors.defaultColor, fontWeight: "400" }}>
              Usuário
            </h3>
            <br />
            <InputMask
              mask={cadastroPessoaFisica ? "999.999.999-99" : "99.999.999/9999-99"}
              placeholder={cadastroPessoaFisica ? "000.000.000-00" : "00.000.000/0000-00"}
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              formErr={formErros}
              formActions={formValidators}
              touched={submited}
              maskChar={null}
              alwaysShowMask
              validations={[required]}
              onChange={(event) =>
                setFormValues({
                  ...formValues,
                  cpf: event.target.value,
                })
              }
              value={formValues.cpf}
            >
              {(inputProps) => <TextField {...inputProps} />}
            </InputMask>
          </Col>
          <Col
            md="3"
            style={{
              height: "130px",
            }}
          >
            <h3 style={{ color: theme.colors.defaultColor, fontWeight: "400" }}>
              Valor Pagamento
            </h3>
            <br />
            <TextField
              placeholder="R$ ..."
              value={formValues.valor || ""}
              mb="1"
              type="text"
              pattern="[0-9]*,[0-9]{3}"
              min={0.0}
              step={0.01}
              onChange={(e) => handleChangeCurrency(e, "valor")}
              validations={[required]}
              setFormErros={setFormErros}
              formValidators={setFormValidators}
              touched={submited}
              formErr={formErros}
            />
          </Col>
          <Col
            md="3"
            style={{
              height: "130px",
            }}
          >
            <h3 style={{ color: theme.colors.defaultColor, fontWeight: "400" }}>
              Mês de Competência
            </h3>
            <br />
            <DatePicker
              type="month"
              style={{ boxShadow: "#A5A5A5" }}
              width="223px"
              value={formValues.mesCompetencia}
              max="2099-12-31"
              min="2023-01-01"
              onChange={(e) => {
                const year = e.target.value.split("-")[0];
                if (year === "" || parseInt(year) > 2099) return;

                setFormValues({
                  ...formValues,
                  mesCompetencia: e.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col
            md="3"
            style={{
              height: "130px",
            }}
          >
            <h3 style={{ color: theme.colors.defaultColor, fontWeight: "400" }}>
              Tipo De Pagamento
            </h3>
            <br />
            <SelectInput
              label={<FormattedMessage id="Tipo Pagamento" />}
              options={TipoPagamento}
              value={
                formValues.motivo
                  ? TipoPagamento.find((e) => e.value === formValues.motivo)
                  : null
              } // Quando motivo é vazio, o select deve exibir um valor nulo ou vazio
              onChange={(e) => {
                setFormValues({
                  ...formValues,
                  motivo: e.value,
                });
              }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col md="4">
            <ButtonDefault
              name={"Liberar"}
              disabled={
                formValues.cpf == 0 ||
                formValues.valor == "" ||
                formValues.motivo == "" ||
                formValues.mesCompetencia == ""
              }
              mr="18px"
              isLoading={isLoading}
              onClick={() => includeNewContract()}
            />
          </Col>

          <Col md="4">
            <Button
              variant="danger"
              style={{ width: "100%" }}
              onClick={(e) => {
                LimparCampos();
              }}
            >
              Limpar
            </Button>
          </Col>
        </Row>
      </Body>
    </React.Fragment>
  );
};


const mapDispatchToProps = (dispatch) => ({
  inviteCandidates: (payload) =>
    dispatch(contractsAction.candidateIncludeNewContractRequest(payload)),

  messagePublication: (messages) =>
    dispatch(messageAction.messagePublication(messages)),

  requestTypeContracts: () => dispatch(contractsAction.typeContractsRequest()),

  redirectToContracts: () => dispatch(push(`${urls.CONTRACTS_LIST}`)),

  requestAdtive: (payload) =>
    dispatch(contractsAction.adtiveTypesRequest(payload)),

  createPayments: (payload) =>
    dispatch(otherPayments.createUserPayments(payload)),
});



const mapStateToProps = (state) => {
  return {
    kitsContratosList: state.contracts.kitsContratosList,
    filesCandidateSelected: state.documents.candidatesFiles,
    log: state.contracts.logProcess,
    adtiveTypesList: state.contracts.adtiveTypesList,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagerPaymentsContract);

ManagerPaymentsContract.propTypes = {
  filesCandidateSelected: PropTypes.func,
};

ManagerPaymentsContract.defaultProps = {
  candidates: () => {},
  filesCandidateSelected: () => {},
};

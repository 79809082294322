import * as routes from "utils/contants/urls";
import theme from "../../../../../components/shared/Theme";
//../../components/shared/Theme
import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { Col, Row } from "react-bootstrap";
import { view, order as orderIcon } from "assets/icons/icons";
import { StatusEtapaContratos } from "utils/contants/enums";
import { Creators as formInputsActions } from "store/ducks/formInputs";
import BreadCrumb from "components/Layout/breadcrumb";
import {
  MultSelectCreatable,
  MultSelect,
  CheckBox,
  ButtonSecondary,
  TextField,
  DatePicker,
} from "components/shared/CustomInputs";
import { Collapse } from "components/shared/CustomComponents";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Creators as messageAction } from "store/ducks/messages";
import { Creators as contractsAction } from "store/ducks/contracts";
import { Creators as userBenefits } from "store/ducks/userBenefits";
import { Body, CustomButtonSecondary } from "./ListContractsCandidatesStyle";
import { orderList, orderListDescending } from "helpers/sharedHelpers";
import Pagination from "components/Pagination";

const ListBenefitsCandidates = ({
  candidates,
  isLoading,
  pagination,
  location,
  messagePublication,
  requestInputValues,
  placesOfWork,
  hasMore,
  getUserBenefits,
  beneficios,
  userBenefitsFiltered,
  requestExportBenefitsInformations,
}) => {
  useEffect(() => {
    userBenefitsFiltered({
      NumeroPagina: 1,
    });
  }, []);

  const [filterItems, setFilterItems] = useState({
    idArea: null,
    idCargo: null,
  });
  const [agruparPorVaga, setAgruparPorVaga] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [order, setOrder] = useState(undefined);
  const [formValues, setFormValues] = useState({
    cpfs: [],
    mesCompetencia: "",
    nomeFuncionario: "",
  });
  const [erroMessage, setErroMessage] = useState({ message: "" });

  const formatCurrency = (value) => {
    const numericValue =
      typeof value === "string"
        ? parseFloat(value.replace(/[^0-9]/g, "")) / 100
        : value;
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(numericValue);
  };

  const AplicaFiltro = (form, firstPage) => {
    userBenefitsFiltered({
      nomeFuncionario: form.nomeFuncionario,
      cpfList: form.cpfs,
      mesCompetencia: form.mesCompetencia,
      NumeroPagina: form.NumeroPagina,
    });

    setFormValues(form);
  };

  const loadMore = () => {
    setClearFilter(false);

    AplicaFiltro(formValues, false);
  };

  const renderIcon = (key) => {
    return orderIcon({
      style: { transform: `rotate(${order !== key ? "0" : "180deg"})` },
    });
  };

  const orderBy = (orderBy, list) => {
    let response;

    switch (orderBy) {
      case "cpf":
        response = orderList("cpf", order, list);
        break;
      case "nome":
        response = orderList("nome", order, list);
        break;
      case "mesCompetencia":
        response = orderList("mesCompetencia", order, list);
        break;
      case "ausencias":
        response = orderList("ausencias", order, list);
        break;
      case "diasUteis":
        response = orderList("diasUteis", order, list);
        break;
      case "valeTransporte":
        response = orderList("valeTransporte", order, list);
        break;
      case "valeRefeicao":
        response = orderList("valeRefeicao", order, list);
        break;
      default:
        response = { list, order: undefined };
        break;
    }
    setOrder(response.order);
  };

  const Filtrar = () => {
    setClearFilter(false);
    AplicaFiltro(formValues, true);
  };

  const getLocationValues = () => {
    if (formValues.idLocations) {
      return placesOfWork
        .filter(function (item) {
          return formValues.idLocations.indexOf(item.id) !== -1;
        })
        .map((e) => ({
          label: e.nome,
          value: e.id,
        }));
    }

    return [];
  };

  const LimparFiltro = () => {
    setClearFilter(true);
    localStorage.removeItem("filter_ListContractsCandidates");
    setFormValues({});
    AplicaFiltro({}, true);
  };

  const TrataMultiInsert = (Insert) => {
    setErroMessage({ message: "" });
    let messages = [];
    let result = [];

    Insert.map((item) => {
      let conc = item.value.trim().split(/[\s,]+/);
      conc.map((filtItem) => {
        let concFiltered = filtItem.replace(/\D/g, "");
      if (concFiltered.length === 11 || concFiltered.length === 14) {
        result.push(concFiltered);
      } else {
        messages.push({
          message: `CPF ${filtItem} precisa ter 11 dígitos. Não será considerado no filtro.`,
          type: "warning",
        });
      }
      });
    });
    messagePublication(messages);
    return result;
  };

  const requestPage = (filter) => {
    userBenefitsFiltered({ NumeroPagina: filter.NumeroPagina });
  };

  const redirect = (url) => {
    window.location.href = url;
  };

  const exportarBeneficios = (form) => {
    requestExportBenefitsInformations({
      nomeFuncionario: form.nomeFuncionario,
      cpfList: form.cpfs,
      mesCompetencia: form.mesCompetencia,
      NumeroPagina: form.NumeroPagina,
    });
  };

  const renderTableItems = (beneficios) => {
    return beneficios?.map((beneficio) => (
      <Link
        onClick={() => {
          redirect(
            `${routes.CONTRACT_CANDIDATE}/${beneficio.candidato_Id}_${beneficio.id}`
          );
        }}
      >
        <TableRow>
          <TableColumn ta="center">
            <Label>{beneficio.cpf}</Label>
          </TableColumn>
          <TableColumn ta="center">
            <Label>{beneficio.candidatoNome}</Label>
          </TableColumn>
          <TableColumn ta="center">
            <Label>{beneficio.mesCompetencia}</Label>
          </TableColumn>

          <TableColumn ta="center">
            <Label>
              {beneficio.dataAtualizacao == ""
                ? beneficio.dataCriacao
                : beneficio.dataAtualizacao}
            </Label>
          </TableColumn>

          <TableColumn ta="center">
            <Label>{beneficio.ausencias}</Label>
          </TableColumn>
          <TableColumn ta="center">
            <Label>{beneficio.diasUteis}</Label>
          </TableColumn>
          <TableColumn ta="center">
            <Label>{formatCurrency(beneficio.valeTransporte)}</Label>
          </TableColumn>
          <TableColumn ta="center">
            <Label>{formatCurrency(beneficio.valeRefeicao)}</Label>
          </TableColumn>
          <TableColumn ta="right" action>
            <Actions>
              <ActionItem>
                {view()}
                <LabelAction>
                  <FormattedMessage id="sharedItems.view" />
                </LabelAction>
              </ActionItem>
            </Actions>
          </TableColumn>
        </TableRow>
      </Link>
    ));
  };

  const renderCandidatesTable = (beneficios) => {
    return (
      <>
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead
                onClick={() => orderBy("cpf", beneficios)}
                style={{ textAlign: "center" }}
              >
                <LabelHeader>
                  <FormattedMessage id="Documento" />
                </LabelHeader>
                {renderIcon("cpf")}
              </TableHead>
              <TableHead
                onClick={() => orderBy("nome", beneficios)}
                style={{ textAlign: "center" }}
              >
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.name" />
                </LabelHeader>
                {renderIcon("nome")}
              </TableHead>
              <TableHead
                onClick={() => orderBy("mesCompetencia", beneficios)}
                style={{ textAlign: "center" }}
              >
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.competenceMonth" />
                </LabelHeader>
                {renderIcon("mesCompetencia")}
              </TableHead>

              <TableHead
                onClick={() => orderBy("mesCompetencia", beneficios)}
                style={{ textAlign: "center" }}
              >
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.updateTime" />
                </LabelHeader>
                {renderIcon("mesCompetencia")}
              </TableHead>

              <TableHead
                onClick={() => orderBy("ausencias", beneficios)}
                style={{ textAlign: "center" }}
              >
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.absence" />
                </LabelHeader>
                {renderIcon("ausencias")}
              </TableHead>
              <TableHead
                onClick={() => orderBy("diasUteis", beneficios)}
                style={{ textAlign: "center" }}
              >
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.businessDays" />
                </LabelHeader>
                {renderIcon("diasUteis")}
              </TableHead>
              <TableHead
                onClick={() => orderBy("valeTransporte", beneficios)}
                style={{ textAlign: "center" }}
              >
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.transportTicket" />
                </LabelHeader>
                {renderIcon("valeTransporte")}
              </TableHead>
              <TableHead
                onClick={() => orderBy("valeRefeicao", beneficios)}
                style={{ textAlign: "center" }}
              >
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.mealTicket" />
                </LabelHeader>
                {renderIcon("valeRefeicao")}
              </TableHead>
              <TableHead style={{ textAlign: "center" }}>
                <LabelHeader>
                  <FormattedMessage id="benefitsCandidateList.export" />
                </LabelHeader>
              </TableHead>
            </TableRow>
            {beneficios.length > 0 && renderTableItems(beneficios)}
          </TableBody>
        </Table>
        <Pagination getItemsPage={requestPage} {...pagination} pageSize={20} />
      </>
    );
  };

  const renderCandidatesByGroupTable = (candidates) =>
    candidates.candidatosPorVaga.map((candidate) => (
      <Collapse
        typeStatus={0}
        position={0}
        title={`Vaga ${candidate.idVaga} - ${candidate.nomeVaga}`}
      >
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead>
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.document" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.nomeCandidato" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.documentosEnviados" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.status" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader ta="center">
                  <FormattedMessage id="documentsCandidateList.analisar" />
                </LabelHeader>
              </TableHead>
            </TableRow>
            {renderTableItems(candidate.candidatesByVacancyList)}
          </TableBody>
        </Table>
      </Collapse>
    ));

  if (isLoading) return <Load isLoading={isLoading} />;

  return (
    <React.Fragment>
      <BreadCrumb title={<FormattedMessage id="manager.selectBenefit" />} />
      <Body>
        <Row>
          <Col md={4}>
            <TextField
              label={<FormattedMessage id="benefitsCandidateList.name" />}
              value={formValues.nomeFuncionario || ""}
              onChange={(e) => {
                const { value } = e.target;
                setFormValues({
                  ...formValues,
                  nomeFuncionario: value,
                });
              }}
            />
          </Col>
          <Col md={4}>
            <MultSelectCreatable
              key={5}
              name={<FormattedMessage id="plugCandidatesList.document" />}
              label={<FormattedMessage id="plugCandidatesList.document" />}
              isMulti
              options={formValues.cpfs}
              value={
                formValues.cpfs
                  ? formValues.cpfs.map((e) => ({
                      label: e,
                      value: e,
                    }))
                  : []
              }
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  cpfs: e ? TrataMultiInsert(e) : [],
                })
              }
            />
          </Col>
          <Col md={4}>
            <DatePicker
              style={{ boxShadow: "#A5A5A5" }}
              width="223px"
              value={formValues.mesCompetencia}
              type="month"
              label={
                <FormattedMessage id="benefitsCandidateList.competenceMonth" />
              }
              name={
                <FormattedMessage id="benefitsCandidateList.competenceMonth" />
              }
              max="2099-12-31"
              min="2023-01-01"
              onChange={(e) => {
                const year = e.target.value.split("-")[0];
                if (year === "" || parseInt(year) > 2099) return;

                setFormValues({
                  ...formValues,
                  mesCompetencia: e.target.value,
                });
              }}
            />
          </Col>
        </Row>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "15px",
          }}
        >
          <Col md={3} style={{ marginTop: "15px" }}>
            <CustomButtonSecondary
              onClick={() => Filtrar()}
              name={<FormattedMessage id="sharedItems.filter" />}
            />
          </Col>
          <Col md={3} style={{ marginTop: "15px" }}>
            <CustomButtonSecondary
              onClick={() => LimparFiltro()}
              name={<FormattedMessage id="sharedItems.clearFilter" />}
            />
          </Col>
          <Col md={3} style={{ marginTop: "15px" }}>
            <CustomButtonSecondary
              onClick={() => exportarBeneficios(formValues)}
              name={<FormattedMessage id="sharedItems.exportBenefits" />}
            />
          </Col>
          <Col md={3} style={{ marginTop: "15px" }}>
            <CustomButtonSecondary
              onClick={() => redirect(`${routes.CONTRACT_MANAGER}`)}
              name={<FormattedMessage id="sharedItems.registerBenefits" />}
            />
          </Col>
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex",
            marginBottom: "15px",
          }}
        >
          <Col md={3} style={{ marginTop: "15px" }}>
            <CustomButtonSecondary
              onClick={() => redirect(`${routes.IMPORT_BENEFITS_BULK}`)}
              name={<FormattedMessage id="sharedItems.registerBenefitsBulk" />}
            />
          </Col>
        </Col>

        {beneficios && !agruparPorVaga && renderCandidatesTable(beneficios)}

        {agruparPorVaga && renderCandidatesByGroupTable(candidates)}
      </Body>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userBenefitsFiltered: (filter) =>
    dispatch(userBenefits.getUserBenefitsFiltered(filter)),

  candidateFiles: (id) => dispatch(contractsAction.candidateFilesRequest(id)),

  setStatusFileRequest: (payload) =>
    dispatch(contractsAction.candidateSetStatusFileRequest(payload)),

  requestInputValues: (inputs) =>
    dispatch(formInputsActions.formInputsRequest(inputs)),

  messagePublication: (messages) =>
    dispatch(messageAction.messagePublication(messages)),

  getUserBenefits: () => dispatch(userBenefits.getUserBenefits()),

  requestExportBenefitsInformations: payload => dispatch(userBenefits.candidatesExportBenefitsInformationRequest(payload)),
});

const mapStateToProps = (state) => ({
  isLoading: state.userBenefits.isLoading,
  candidates: state.contracts.candidates,
  pagination: state.userBenefits.pagination,
  quantidadeItens: state.contracts.quantidadeItens,
  beneficios: state.userBenefits.beneficiosList,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListBenefitsCandidates);

ListBenefitsCandidates.propTypes = {
  candidates: PropTypes.func,
  page: PropTypes.any,
  hasMore: PropTypes.any,
  quantidadeItens: PropTypes.any,
  beneficios: PropTypes.any,
};

ListBenefitsCandidates.defaultProps = {
  candidates: () => {},
};

import * as urls from "utils/contants/urls";
import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "components/shared/CustomComponentsStyle";

import { push } from "connected-react-router";
import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { editIcon } from "assets/icons/icons";
import { trashIcon } from "assets/icons/icons";
import BreadCrumb from "components/Layout/breadcrumb";

import {
  SelectFieldInput,
  TextAreaField,
  ButtonDefault,
  ButtonSecondary,
  MultSelect,
  TextField,
  SelectInput
} from "components/shared/CustomInputs";

import { FormattedMessage } from "react-intl";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Creators as contractsAction } from "store/ducks/contracts";
import { Creators as userBenefits } from "store/ducks/userBenefits";
import { Creators as otherPayments } from "store/ducks/otherPayments";

import { Title, YesOrNoDialog } from "components/shared/CustomComponents";
import { Body } from "./DetailContractCandidateStyle";
import { TipoPagamento } from "../../../../../utils/contants/enums";

const DetailPaymentCandidate = ({
  match,
  isLoading,
  location,
  redirectToContracts,
  requestTypeContracts,
  requestAdtive,
  beneficios,
  userBenefitsById,
  removerValeTransporte,
  atualizarBeneficios,
  inativarBeneficios,
  beneficioAtualizado,
  userPayment,
  Pagamento,
}) => {
  useEffect(() => {
    const { params } = match;
    if (params.id) {
      userPayment({ id: params.id });
    }
  }, []);

  const [showDialogYesOrNo, setShowDialogYesOrNo] = useState(false);
  const token = localStorage.getItem("Authorization");

  const [indiceBeneficioEditar, setIndiceBeneficioEditar] = useState(0);

  const canShowDialog = (idDoc) => {
    return showDialogYesOrNo == idDoc;
  };

  const [formUserAdminValues, setFormUserAdminValues] = useState({
    name: "",
    pessoa_Id: "",
    id: null,
  });

  useEffect(() => {
    if (formUserAdminValues.id)
      requestAdtive({
        id: formUserAdminValues.id,
        name: formUserAdminValues.name,
      });
  }, [formUserAdminValues.id]);

  useEffect(() => {
    requestTypeContracts();
  }, []);
  const [aditiveType, setAditiveType] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    if (beneficioAtualizado) setShowDialog(false);
  }, [beneficioAtualizado]);

  const renderFilesCandidatesTable = (
    Pagamento,
    setStatusFile,
    token,
    canShowDialog,
    showDialogYesOrNo,
    setShowDialogYesOrNo
  ) => {
    return (
      <div>
        <br />
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead style={{ width: "5px" }}>
                <LabelHeader>ID</LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>Documento</LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>Nome</LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>Mês De Competência</LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>Motivo</LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>Valor Pago</LabelHeader>
              </TableHead>
            </TableRow>
            {Pagamento.length > 0 &&
              renderTableFilesItems(
                Pagamento,
                userBenefitsById,
                setShowDialogYesOrNo,
                showDialogYesOrNo
              )}
          </TableBody>
        </Table>
        <br />
      </div>
    );
  };

  function RemoveBeneficio(indice, listaBeneficios) {
    inativarBeneficios(listaBeneficios);
  }

  const renderTableFilesItems = (
    Pagamento,
    userBenefitsById,
    showDialogYesOrNo,
    setShowDialogYesOrNo
  ) =>
    Pagamento.map((beneficios, index) => (
      <TableRow>
        <TableColumn ta="left">
          <Label>{beneficios.id}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{beneficios.cpf}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{beneficios.nome}</Label>
        </TableColumn>
        <TableColumn ta="left ">
          <Label>{beneficios.mesCompetencia}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{beneficios.motivo}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{`${"R$ "}`+ beneficios.pagamento}</Label>
        </TableColumn>
        <TableColumn ta="right" action>
          <Actions
            flexPosition="flex-start"
            onClick={() => setShowDialog(true)}
            style={{ cursor: "progress" }}
          >
            <ActionItem>
              {editIcon()}
              <LabelAction>
                <FormattedMessage id="Editar" />
              </LabelAction>
            </ActionItem>
          </Actions>
        </TableColumn>
        <TableColumn ta="right" action>
          <Actions
            flexPosition="flex-start"
            onClick={() => {
              setShowDialogYesOrNo(true);
            }}
            style={{ cursor: "progress" }}
          >
            <ActionItem>
              {trashIcon()}
              <LabelAction>
                <FormattedMessage id="Remover" />
              </LabelAction>
            </ActionItem>
          </Actions>
        </TableColumn>

        <YesOrNoDialog
          show={showDialogYesOrNo}
          cancelAction={() => {
            setShowDialogYesOrNo(false);
          }}
          confirmAction={() => {
            {
              RemoveBeneficio(0, beneficios);
              setShowDialogYesOrNo(false);
            }
          }}
          question={<FormattedMessage id="manager.realmenteDesejaDeletar" />}
        />
      </TableRow>
    ));

  if (isLoading) return <Load isLoading={isLoading} />;

  return (
    <React.Fragment>
      <BreadCrumb
        location={location}
        title={"Beneficiário: " + Pagamento[0].nome}
      />
      <Body>
        <ButtonDefault
          onClick={() => redirectToContracts()}
          width="230px"
          mr="10px"
          name={"Voltar"}
        />
        <br />
        {renderFilesCandidatesTable(
          Pagamento,
          userBenefitsById,
          token,
          canShowDialog,
          setShowDialogYesOrNo,
          showDialogYesOrNo,
          atualizarBeneficios
        )}
        {Pagamento.length > 0 && (
          <React.Fragment>
            <DialogInsertObservacao
              show={showDialog}
              formValues={Pagamento}
              setFormValues={userBenefitsById}
              setShowDialog={setShowDialog}
              indiceBeneficio={indiceBeneficioEditar}
              atualizarBeneficios={atualizarBeneficios}
            />
          </React.Fragment>
        )}
      </Body>
    </React.Fragment>
  );
};

const DialogInsertObservacao = ({
  show,
  formValues,
  setShowDialog,
  indiceBeneficio,
  atualizarBeneficios,
}) => {
  useEffect(() => {
  }, [formValues]);

  const closeModal = () => {
    setShowDialog(!show);
  };

  const handleClose = () => setShowDialog(false);
  const [Beneficio, setBeneficio] = useState(formValues[0] || {});
  const [motivos, setMotivos] = useState([]);

  useEffect(() => {
    if (TipoPagamento != undefined && TipoPagamento.length > 0) {
      var options = TipoPagamento.map((e, index) => ({
        value: e.value,
        label: e.label,
      }));
      setMotivos(options);
    }
  }, [TipoPagamento]);

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Title
          size="1.500em"
          color="title"
          title={<FormattedMessage id="contracts.sendConfirmMsgTitleBenefit" />}
        />
      </Modal.Header>
      <Modal.Body>
        <Row style={{ marginTop: "10px" }}>
          <Col
            lg={6}
            style={{
              marginTop: "10px",
              display: "flex",
              flexFlow: "column",
              padding: "15px",
            }}
          >
            <Col md={12}>
              <SelectInput
                options={motivos}
                onChange={(e) => {
                  setBeneficio({
                    ...Beneficio,
                    motivo: e.value,
                  });
                }}
                value={motivos.find((e) => {
                  return e.label === Beneficio.motivo;
                })}
                label={<FormattedMessage id="Motivo do Pagamento" />}
              />
            </Col>
          </Col>
          <Col
            lg={6}
            style={{
              marginTop: "10px",
              display: "flex",
              flexFlow: "column",
              padding: "15px",
            }}
          >
            <Col md={12}>
              <TextField
                label={<FormattedMessage id="Valor do Vale Refeição" />}
                value={Beneficio?.pagamento || ""}
                onChange={(e) => {
                  let money = e.target.value;
                  money = money.replace(/\D/g, "");
                  money = (parseFloat(money) / 100).toFixed(2).replace(".", ",");
                  money = `R$ ${money}`;
                  setBeneficio({
                    ...Beneficio,
                    pagamento: money,
                  });
                }}
              />
            </Col>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Col lg={{ size: 2, offset: 6 }}>
          <ButtonSecondary
            onClick={() => closeModal()}
            name={<FormattedMessage id="sharedItems.cancel" />}
          />
        </Col>
        <Col lg={{ size: 2 }}>
          <ButtonDefault
            onClick={() => {
              atualizarBeneficios({ Beneficio, indiceBeneficio });
              closeModal();
            }}
            name={<FormattedMessage id="Atualizar" />}
          />
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userPayment: (payload) =>
    dispatch(otherPayments.getUserPaymentsFiltered(payload)),

  atualizarBeneficios: (payload) =>
    dispatch(otherPayments.atualizarUserPaymentsById(payload)),

  inativarBeneficios: (payload) =>
    dispatch(otherPayments.inativarUserBenefitsById(payload)),

  redirectToContracts: (id) => dispatch(push(`${urls.PAYMENTS_LIST}`)),

  requestTypeContracts: (payload) =>
    dispatch(contractsAction.typeContractsRequest()),

  requestAdtive: (payload) =>
    dispatch(contractsAction.adtiveTypesRequest(payload)),
});

const mapStateToProps = (state) => ({
  isLoading: state.contracts.isLoading,
  listUserAdmins: state.contracts.listUserAdmins,
  beneficios: state.userBenefits.beneficiosList,
  beneficioRemovido: state.userBenefits.ItemRemovido,
  beneficioAtualizado: state.otherPayments.ItemAtualizado,
  itemInativado: state.userBenefits.ItemInativado,
  Pagamento: state.otherPayments.Pagamento,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailPaymentCandidate);

DetailPaymentCandidate.propTypes = {
  filesCandidateSelected: PropTypes.func,
  beneficios: PropTypes.any,
  beneficioRemovido: PropTypes.any,
  beneficioAtualizado: PropTypes.any,
  itemInativado: PropTypes.any,
  Pagamento: PropTypes.any,
};

DetailPaymentCandidate.defaultProps = {
  candidates: () => {},
  filesCandidateSelected: () => {},
  beneficios: () => {},
  Pagamento: () => {},
  beneficioRemovido: false,
  itemInativado: false,
  beneficioAtualizado: false,
};

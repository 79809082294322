import * as routes from "utils/contants/urls";

import {
  ActionItem,
  Actions,
  Label,
  LabelAction,
  LabelHeader,
  Table,
  TableBody,
  TableColumn,
  TableHead,
  TableRow,
} from "../../../../../components/shared/CustomComponentsStyle";

import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { view } from "assets/icons/icons";
import { Creators as formInputsActions } from "store/ducks/formInputs";
import { Body } from "./EnviarDocumentsCandidatesStyle";
import BreadCrumb from "components/Layout/breadcrumb";

import {
  MultSelectCreatable,
  MultSelect,
  ButtonSecondary,
  TextField,
} from "components/shared/CustomInputs";
import { Collapse } from "components/shared/CustomComponents";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import Load from "components/Layout/Load";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Creators as documentsAction } from "store/ducks/documents";
import { Creators as candidatesAction } from "store/ducks/candidates";
import { StatusEtapaDocumentos, TipoDeDocumento } from "utils/contants/enums";
import { Creators as messageAction } from "store/ducks/messages";
import { Creators as candidateActions } from "../../../../../store/ducks/candidates";
import { Creators as enviarDocumentsActions } from "../../../../../store/ducks/enviarDocuments";
import { Icon } from "react-icons-kit";
import { ic_cloud_upload_outline } from "react-icons-kit/md/ic_cloud_upload_outline";
import Pagination from "components/Pagination";

import { Form, Button, Modal, InputGroup, Dropdown } from "react-bootstrap";

import ButtonUpload from "../../../../../components/Layout/ButtonUpload";


import { useSelector } from "react-redux";
const RowColorStage = (status) => {
  let color;
  switch (status) {
    case "Em análise":
      color = "#f44336";
      break;
    case "Aprovado":
      color = "rgb(95 227 100)";
      break;
    case "Desistente":
      color = "#86868666";
      break;
    case "Pendente de reenvio":
      color = "#A020F0";
      break;
    default:
      color = "orange";
      break;
  }

  return {
    height: "18px",
    width: "18px",
    borderRadius: "50%",
    float: "left",
    display: "inline-block",
    backgroundColor: color,
  };
};

const redirect = (url) => {
  window.location.href = url;
};

const EnviarDocumentsCandidates = ({
  candidateVacancysDocs,
  candidates,
  isLoading,
  pagination,
  location,
  requestInputValues,
  placesOfWork,
  messagePublication,
  hasMore,
  releaseStageForCandidatesRequest,
  requestUploadDocument,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const [agruparPorVaga, setAgruparPorVaga] = useState(false);
  const [clearFilter, setClearFilter] = useState(false);
  const [dataCandidate, setDataCandidate]= useState({})
  const [formValues, setFormValues] = useState({
    cpfs: [],
    idVagas: [],
    idLocations: [],
    dataAdmissao: "",
    nomeFuncionario: "",
  });
  const [erroMessage, setErroMessage] = useState({ message: "" });
  const [contextCandidates, setContextCandidates] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (nomeFuncionario, cpf, id) => {
    setShow(true);
    setFormCandidatesSelect({ nomeFuncionario, cpf, id });
  };

  const [formCandidatesSelect, setFormCandidatesSelect] = useState([]);

  const formValuesRef = useRef([formValues]);

  const AplicaFiltro = (form, firstPage) => {
    
    localStorage.setItem(
      "filter_EnviarDocumentsCandidates",
      JSON.stringify(form)
    );

    candidateVacancysDocs({
      nomeFuncionario: form.nomeFuncionario,
      cpfList: form.cpfs,
      idVagasList: form.idVagas,
      idLocaisList: form.idLocations,
      dataAdmissaoPrevista: form.dataAdmissao,
      idStatusList: form.idStatus,
      dateInitActivity: form.dateInitActivity,
      firstPage,
    });
    setFormValues(form);
  };

  const loadMore = () => {
    setClearFilter(false);
    AplicaFiltro(formValues, false);
  };

  useEffect(() => {
    requestInputValues(["placeOfWork"]);
    let filter_storaged = JSON.parse(
      localStorage.getItem("filter_EnviarDocumentsCandidates")
    );

    if (filter_storaged != null) {
      setFormValues(filter_storaged);
      candidateVacancysDocs({
        cpfList: filter_storaged.cpfs,
        firstPage: true,
        recoveredFilter: true,
        quantidadeItens: filter_storaged.quantidadeItens,
        nomeFuncionario: filter_storaged.nomeFuncionario,
      });
    } else {
      candidateVacancysDocs({
        cpfs: [],
        idVagas: [],
        idLocations: [],
        firstPage: true,
      });
    }
  }, []);

  useEffect(() => {
    if (candidates.candidatos != undefined && candidates.candidatos.length) {
      const dupContextList = candidates.candidatos.map((candidate) => {
        return { ...candidate, selected: false };
      });

      setContextCandidates(dupContextList);
    }

    let filter_storaged = JSON.parse(
      localStorage.getItem("filter_EnviarDocumentsCandidates")
    );
    if (filter_storaged != null && !clearFilter) {
      filter_storaged.quantidadeItens = candidates.candidatos
        ? candidates.candidatos.length
        : 20;
      localStorage.setItem(
        "filter_EnviarDocumentsCandidates",
        JSON.stringify(filter_storaged)
      );
      setFormValues(filter_storaged);
    } else {
      setFormValues({
        cpfs: [],
        idVagasList: [],
        idVagas: [],
        idLocations: [],
        idStatus: [],
        dateInitActivity: null,
      });
    }

    if (candidates.candidatos == undefined) {
      AplicaFiltro(formValues, true);
    }
  }, [candidates]);

  const Filtrar = () => {
    setClearFilter(false);
    AplicaFiltro(formValues, true);
  };

  const getStatusEtapaValues = () => {
    if (formValues.idStatus) {
      return StatusEtapaDocumentos.filter(function (item) {
        return formValues.idStatus.indexOf(item.value) !== -1;
      });
    }

    return [];
  };

  const LimparFiltro = () => {
    setClearFilter(true);
    localStorage.removeItem("filter_EnviarDocumentsCandidates");
    setFormValues({});
    AplicaFiltro({}, true);
  };

  const TrataMultiInsert = (Insert) => {
    setErroMessage({ message: "" });
    let messages = [];
    let result = [];
    Insert.map((item) => {
      let conc = item.value.trim().split(/[\s,]+/);
      conc.map((filtItem) => {
        let concFiltered = filtItem.replace(/\D/g, "");
        if (concFiltered.length === 11 || concFiltered.length === 14) {
          result.push(concFiltered);
        } else {
          messages.push({
            message: `CPF ${filtItem} precisa ter 11 dígitos. Não será considerado no filtro.`,
            type: "warning",
          });
        }
      });
    });
    messagePublication(messages);
    return result;
  };

  const selectCandidate = (id) => {
    const dupContextList = contextCandidates.map((item) => {
      if (item.idCandidatoNaEtapa === id) {
        item.selected = !item.selected;
      }
      return item;
    });
    setContextCandidates(dupContextList);
  };

  const liberarCandidatos = () => {
    const selectedCandidates = contextCandidates
      .filter((candidate) => candidate.selected)
      .map((candidate) => {
        return candidate.idCandidatoNaEtapa;
      });
    releaseStageForCandidatesRequest({
      idsCandidatosEtapa: selectedCandidates,
    });
  };

  const requestPage = (filter) => { 
    candidateVacancysDocs(filter);
  };

  const renderTableItems = (candidatesList) =>
    candidatesList.map((candidate) => (
      <TableRow>
        <TableColumn>
          <Label>{candidate.cpf}</Label>
        </TableColumn>
        <TableColumn ta="left">
          <Label>{candidate.nome}</Label>
        </TableColumn>

        <TableColumn ta="right" action>
          <div style={{ display: "flex", margin: "20px", justifyContent: "flex-end"}}>
            <Link
              onClick={() =>
                  redirect(`${routes.UPLOAD_DOCUMENTS}/${candidate.idCandidatoNaEtapa}`)
              }
            >
              <Actions>
                <ActionItem>
                  {view()}
                  <LabelAction>
                    <FormattedMessage id="sharedItems.view" />
                  </LabelAction>
                </ActionItem>
              </Actions>
            </Link>
            <Link
              onClick={() => {
                setDataCandidate(candidate);
                handleShow(candidate.nome, candidate.cpf, candidate.id);
              }}
            >
              <Actions>
                <ActionItem style={{ marginLeft: "8px", marginTop: "4px" }}>
                  <Icon icon={ic_cloud_upload_outline} size={21} />
                  <LabelAction>
                    <FormattedMessage id="sharedItems.upload" />
                  </LabelAction>
                </ActionItem>
              </Actions>
            </Link>
          </div>
        </TableColumn>
      </TableRow>
    ));

  const RenderModalEnviarDoc = ({
    show,
    handleClose,
    formValeusModal,
    isLoading,
  }) => {


    const [selectedOptions, setSelectedOptions] = useState([]);
    const [fileUpload, setfileUpload] = useState();
    const [mesReferencia, setMesReferencia] = useState('');

  
    const uploadDoc = (e, idDocument, idCandidato, dataDocumentoRH) => {
      requestUploadDocument({
        file: e,
        idDocument: idDocument,
        idCandidato: idCandidato,
        dataDocumentoRH : dataDocumentoRH
      });
      e = null;
    };

    const handleMultSelectChange = (selectedValues) => {
      setSelectedOptions(selectedValues.value);
    }; 

    const handleSendClick = (fileUploaded, idFile, idCandidato, dataDocumentoRH) => {
      uploadDoc(fileUploaded, idFile, idCandidato, dataDocumentoRH);
      handleClose();
    };



  const handleMudancaMesReferencia = (event) => {
    const dateDocument = event.target.value;
    setMesReferencia(dateDocument);
  };

    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="modalUpload.selectDocumet" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <FormattedMessage id="modalUpload.name" />
              </Form.Label>
              <Form.Control
                type="text"
                disabled
                autoFocus
                value={formValeusModal.nomeFuncionario}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>
                <FormattedMessage id="modalUpload.document" />
              </Form.Label>
              <Form.Control
                type="text"
                disabled
                autoFocus
                value={formValeusModal.cpf}
              />
            </Form.Group>
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label>
                <FormattedMessage id="modalUpload.selectOption" />
              </Form.Label>
              <MultSelect
                options={TipoDeDocumento}
                onChange={handleMultSelectChange}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Mês de referência</Form.Label>
              <Form.Control
                type="month"
                placeholder="Selecione o mês e ano"
                value={mesReferencia}
                onChange={handleMudancaMesReferencia}
              />
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label
                hidden={
                  selectedOptions == 6 ||
                  selectedOptions == 7 ||
                  selectedOptions == 9 ||
                  selectedOptions == 10 ||
                  selectedOptions == 11 ||
                  selectedOptions == 12 ||
                  selectedOptions == 13
                }
              >
                <FormattedMessage id="modalUpload.selectFile" />{" "}
              </Form.Label>
              <Form.Control
                type="file"
                hidden={
                  selectedOptions == 6 ||
                  selectedOptions == 7 ||
                  selectedOptions == 9 ||
                  selectedOptions == 10 ||
                  selectedOptions == 11 ||
                  selectedOptions == 12 ||
                  selectedOptions == 13
                }
                isLoading={isLoading}
                onChange={(event) => {
                  setfileUpload(event.target.files[0]);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            <FormattedMessage id="modalUpload.close" />
          </Button>
          <Button
            style={{ background: "#002D4F", borderColor: "#002D4F" }}
            onClick={() => {
              if (
                selectedOptions == 6 ||
                selectedOptions == 7 ||
                selectedOptions == 9 ||
                selectedOptions == 10 ||
                selectedOptions == 11 ||
                selectedOptions == 12 ||
                selectedOptions == 13
              )
                redirect(
                  `${routes.UPLOAD_DOCUMENTS_SIGNATURE}?candidato=${formValeusModal.id}&documento=${selectedOptions}&mesReferencia=${mesReferencia}`
                );
              else
                handleSendClick(
                  fileUpload,
                  selectedOptions,
                  formValeusModal.id,
                  mesReferencia
                );
            }}
          >
            <FormattedMessage
              id={
                selectedOptions == 6 ||
                selectedOptions == 7 ||
                selectedOptions == 9 ||
                selectedOptions == 10 ||
                selectedOptions == 11 ||
                selectedOptions == 12 ||
                selectedOptions == 13
                  ? "modalUpload.selectFile"
                  : "modalUpload.send"
              }
            />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const renderCandidatesTable = (candidatesList) => {
    return (
      <>
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead>
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.document" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.nomeCandidato" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="right">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.documents" />
                </LabelHeader>
              </TableHead>
            </TableRow>
            {renderTableItems(contextCandidates)}
          </TableBody>
        </Table>
        <Pagination getItemsPage={requestPage} pageSize={50} {...pagination} />
      </>
    );
  };

  const renderCandidatesByGroupTable = (candidatesList) =>
    candidatesList.candidatosPorVaga.map((candidate) => (
      <Collapse
        typeStatus={0}
        position={0}
        title={`Vaga ${candidate.idVaga} - ${candidate.nomeVaga}`}
      >
        <Table>
          <TableBody>
            <TableRow noBorder>
              <TableHead>
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.document" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.nomeCandidato" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="right">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.documentosEnviados" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader>
                  <FormattedMessage id="documentsCandidateList.status" />
                </LabelHeader>
              </TableHead>
              <TableHead ta="left">
                <LabelHeader ta="center">
                  <FormattedMessage id="documentsCandidateList.analisar" />
                </LabelHeader>
              </TableHead>
            </TableRow>
            {renderTableItems(candidate.candidatesByVacancyList)}
          </TableBody>
        </Table>
      </Collapse>
    ));

  if (isLoading) return <Load isLoading={isLoading} />;
  return (
    <React.Fragment>
      <BreadCrumb
        location={location}
        title={<FormattedMessage id="Sessão de Documentos" />}
      />
      <Body>
        <Row style={{ marginBottom: "10px" }}>
          <Col md={4}>
            <MultSelectCreatable
              key={5}
              name={<FormattedMessage id="documentsCandidateList.document" />}
              label={<FormattedMessage id="documentsCandidateList.document" />}
              isMulti
              options={formValues.cpfs}
              value={
                formValues.cpfs
                  ? formValues.cpfs.map((e) => ({
                      label: e,
                      value: e,
                    }))
                  : []
              }
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  cpfs: e ? TrataMultiInsert(e) : [],
                })
              }
            />
          </Col>
          <Col md={4}>
            <TextField
              name={<FormattedMessage id="Nome Colaborador" />}
              label={<FormattedMessage id="Nome Colaborador" />}
              defaultValue={formValues.nomeFuncionario}
              value={formValues.nomeFuncionario}
              placeholder=""
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  nomeFuncionario: e.target.value,
                })
              }
            />
          </Col>
          <Col md={3} style={{ marginTop: "15px" }}>
            <ButtonSecondary
              onClick={() => Filtrar()}
              name={<FormattedMessage id="sharedItems.filter" />}
            />
          </Col>
          <Col md={3} style={{ marginTop: "15px" }}>
            <ButtonSecondary
              onClick={() => LimparFiltro()}
              name={<FormattedMessage id="sharedItems.clearFilter" />}
            />
          </Col>
        </Row>
        {candidates.candidatos &&
          !agruparPorVaga &&
          renderCandidatesTable(candidates)}

        {agruparPorVaga && renderCandidatesByGroupTable(candidates)}
      </Body>
      <RenderModalEnviarDoc
        show={show}
        handleClose={handleClose}
        formValeusModal={formCandidatesSelect}
        isLoading={isLoading}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  candidateVacancysDocs: (filter) =>
    dispatch(enviarDocumentsActions.enviarDocsEmployeesRequest(filter)),
  candidateFiles: (id) => dispatch(documentsAction.candidateFilesRequest(id)),
  setStatusFileRequest: (payload) =>
    dispatch(documentsAction.candidateSetStatusFileRequest(payload)),
  requestInputValues: (inputs) =>
    dispatch(formInputsActions.formInputsRequest(inputs)),
  messagePublication: (messages) =>
    dispatch(messageAction.messagePublication(messages)),
  releaseStageForCandidatesRequest: (payload) =>
    dispatch(documentsAction.releaseStageForCandidatesRequest(payload)),
  requestExportPersonalInformations: (payload) =>
    dispatch(
      candidatesAction.candidatesExportPersonalInformationRequest(payload)
    ),
  releasePasswordReset: (payload) =>
    dispatch(candidatesAction.candidatesPasswordResetRequest(payload)),
  requestUploadDocument: (payload) =>
    dispatch(candidateActions.candidateDocumentUpdateRequest(payload)),
});

const mapStateToProps = (state) => ({
  isLoading: state.enviarDocuments.isLoading,
  candidates: state.enviarDocuments.candidates,
  placesOfWork: state.formInputs.placeOfWork,
  hasMore: state.enviarDocuments.hasMore,
  quantidadeItens: state.enviarDocuments.quantidadeItens,
  pagination: state.enviarDocuments.pagination,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnviarDocumentsCandidates);

EnviarDocumentsCandidates.propTypes = {
  candidates: PropTypes.func,
  hasMore: PropTypes.any,
  quantidadeItens: PropTypes.any,
};

EnviarDocumentsCandidates.defaultProps = {
  candidates: () => {},
};

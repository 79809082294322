import theme from "../components/shared/Theme";

export default {
  "pt-BR": {
    stageList: {
      data: "Dados Cadastrais",
      test: "Teste",
      trainner: "Treinamento",
      assay: "Análise",
      medic: "Exame Médico",
      preAdmition: "Pré admissão",
      admition: "Admissão",
      ambietation: "Ambientação",
      feedback: "FeedBack",
      contract: "Contrato",
      table: {
        kindTest: "TIPO DE TESTE",
        kind: "TIPO",
        nameTest: "NOME DO TESTE",
        level: "NÍVEL",
        avaliation: "AVALIAÇÃO",
        questionsTime: "TEMPO DO TESTE",
        title: "TÍTULO",
        name: "NOME",
        amountOfQuestions: "QUANT PERGUNTAS",
      },
    },
    question: {
      typeSelect: "Tipo de resposta",
      selectCorrectAnsewr: "Selecione a resposta correta",
      correctAnsewr: "Resposta correta",
      mandatory: "Obrigatória",
    },
    testForm: {
      breadCrumb: "Nova etapa de teste",
      newTeste: "Nova etapa de teste",
    },
    defaultStage: {
      stagesNames: {
        trainner: "Nova treinamento",
        test: "Novo Teste",
        assay: "Nova Análise",
        medicExame: "Novo Exame Médico",
        admition: "Nova Admissão",
        ambietation: "Nova Ambientação",
        feedBack: "Novo FeedBack",
        newEstage: "Nova Etapa",
        preAdmition: "Pré admissão",
        contract: "Novo contrato",
      },
    },
    newStageAboutYou: {
      items: {
        default:
          "Este Bloco contem perguntas de carater pessoal e informativo. Busca saber dados pessoais e conhecer um pouco sobre a pessoa, suas habilidades, aspirações, conhecimentos, qualidades e defeitos.",
      },
      breadCrumb: "Dados cadastrais",
      trash: "VOLTAR",
      associationJob: "ASSOCIAR CARGO",
      edit: "EDITAR",
      stagesTitle: {
        default: "Dados básicos",
        aboutYou: "Sobre você",
        carrier: "Carreira",
        formation: "Formação",
        goal: "Objetivos",
      },
      dialog: {
        question: "Excluir esta etapa ?",
        questionConfirmation: "Tem certeza que deseja excluir esta etapa?",
      },
      mandatory: "Todos os cargos",
    },
    inputs: {
      affiliate: "Coligada",
      resultCenter: "Centro de resultado",
      section: "Seção",
      Projeto: "Projeto",
      infoTax: "Taxa informada",
      proj: "Projeto",
      workLocation: "Local de trabalho",
      taker: "Tomador",
      managerCode: "Matrícula do gestor",
      managerName: "Nome do gestor",
      area: "Área",
      job: "Cargo",
      vacancyCode: "Código da vaga",
      howManyVacancy: "Quantidade de vagas",
      journey: "Jornada",
      periode: "Horário",
      periodeRealization: "Periodo de realização",
      periodeAvaliation: "Periodo de avaliação",
      placeOfWork: "Local de trabalho",
      description: "Descrição da vaga",
      salary: "Salário",
      startWork: "Início das atividades",
      temporariVacancy: "Vaga Temporária",
      schooling: "Escolaridade",
      areaFormation: "Área de formação",
      tecnicKnowledge: "Conhecimento técnico",
      competence: "Competências",
      disabledPerson: "Aceitar pessoas com deficiência",
      timeOfWork: "Tempo de empresa (mês)",
      lastPromotion: "Última promoção (mês)",
      city: "Cidades elegíveis",
      eligibleJobs: "Cargos elegíveis",
      selectAllJobs: "Selecionar todos",
      areaOfWork: "Local de trabalho",
      funcCode: "Matrícula do colaborador",
      funcName: "Nome do colaborador",
      sector: "Setor",
      selectArea: "Selecione a área",
      autoCorrect: "Correção automática",
      aprovation: "Aprovação",
      showSieveApproval: "Mostrar Crivo",
      showCandidateScore: "Mostrar Nota",
      time: "Tempo",
      weight: "Peso",
      kindAnserw: "Tipo de resposta",
      multSelect: "Multi-select",
      uniqSelect: "Seleção única",
      number: "Numérico",
      date: "Data",
      text: "Texto",
      redaction: "Redação",
      startHour: "Hora Inicial",
      endHour: "Hora final",
      placeOfInterview: "Local da entrevista",
      placeOfExam: "Local do exame",
      responsibility: "Responsabilidades",
      titleVaga: "Título da vaga",
      informeSalary: "Informar faixa salarial",
      confidentialVacancy: "Vaga confidencial",
      showSalary: "Exibir salário no portal",
      informeSchedules: "Informar faixa de horário",
      aboutWork: "Sobre o trabalho",
      skills: "Habilidades",
      benefits: "Benefícios",
      experiences: "Experiencias",
      deficiency: "Necessidade especiais",
      vacancyReq: "Requisitos da vaga",
      select: "Selecione...",
      jobAndArea: "Cargo ou área",
      vacancyGroup: "Agrupar vaga(Opcional)",
      numberSMP: "Número SMP",
      note: "Observações",
    },
    myProfile: {
      digitalSignature: {
        titlePage: "VINCI - Relacionamento com responsabilidade",
        noAudio: "*O vídeo não possui áudio",
        sendFinish: "Confirmar Envio",
        view: "Visualizar o Contrato",
        download: "Download do Contrato",
        add: "Informar Divergências",
        divergencias: "Divergências",
        sendDivergencias: "Enviar a Divergência para Admissão",
        description:
          "Descreva detalhadamente a divergência identificada no documento.",
        acceptCommitTerms: "Li e aceito o TERMO DE CIÊNCIA presente acima",
        termoCiencia:
          "Declaro a veracidade das informações aqui contidas e autorizo a empresa AeC a proceder com as conferências e levantamentos necessários. Declaro também ter ciência de que o envio do Contrato de Trabalho assinado não caracteriza qualquer vínculo empregatício com minha pessoa e que a confirmação da contratação será dada após a avaliação do documento, aprovação na etapa e assinatura da carteira de trabalho.",
        selectAndSend: "Anexar Documento Assinado",
        titlePage: "Contrato de trabalho: Assinatura Digital",
        stepBlockedBeforeAttachmentDocumento:
          "*Essa etapa somente ficará habilitada após o anexo do arquivo.",
        descriptionStep4:
          "Após seguir com todas as etapas anteriores, clique no botão “Confirmar Envio“ para concluir o processo e enviar o contrato assinado.",
        step4: "Passo 4: Confirmar Envio",
        descriptionStep3:
          "Concordando com todos os termos descritos nos contratos acima, efetue a assinatura abaixo, a mesma assinatura será anexada em todos os outros documentos. Caso encontre alguma informação incorreta clique em “RELATAR DIVERGÊNCIAS”.",
        step3: "Passo 3: Assinar a documentação.",
        descriptionStep2:
          "Leia atentamente as documentações e, concordando com todos os termos, siga para o passo 3: Assinatura.",
        step2: "Passo 2: Ler atentamente a documentação.",
        step1: "Passo 1: Aderência aos planos de saúde e odontológico.",
        descriptionStep1:
          "Leia atentamente a descrição dos valores e preencha o formulário com a opção pela aderência ou não aos planos de saúde e odontológico.",
        sendDocumento: "Anexar Documento",
      },
    },
    login: {
      buttonLogin: "Entrar",
      reset: "Redefinir",
      password: "Senha",
      forgotPassword: "Esqueci minha senha.",
      back: "Voltar",
      disclaimerForgot:
        "Insira seu endereço de e-mail que lhe enviaremos um link de redefinição de senha",
      newPassword: {
        password: "Senha Atual",
        rePassword: "Recadastrar senha",
        newPassword: "Digite sua nova senha",
        confirmPassword: "Confirme sua nova senha",
        formatPassword:
          "A senha deve ter no minimo 8 caracteres, letra maiscula e miniscula , números e caractere especial",
      },
    },
    homeOptions: {
      logout: "Fazer logout",
      welcome: "Bem-Vindo",
      talentsModule: "Start",
      choiceModule: "Journey",
      trainnerModule: `${theme.words.nameMark} Trainner`,
      managerModule: "Configurações",
      changePassword: "Trocar senha",
    },
    menuTalentBar: {
      dashboard: "Dashboard",
      start: "Inicial",
      vacancy: "Vagas",
      stage: "Etapas",
      candidates: "Colaboradores",
      rails: "Trilhas",
      selectVacancy: "Selecione a vaga",
      selectCandidate: "Selecione o colaborador",
      managers: "Administradores",
    },
    vacancyList: {
      trail: "Trilha",
      stop: "Paralisar",
      statusEnabledVacancy: "Dentro do Prazo",
      statuswaitingVacancy: "Próximo ao vencimento",
      statusCanceledVacancy: "Fora do prazo",
      draft: "Não Publicadas",
      open: "Publicadas",
      inProgress: "Em Andamento",
      newVacancy: "Nova Vaga",
      editVacancy: "Editar Vaga",
      finished: "Finalizadas/Cancelada",
      columns: {
        code: "Código",
        vacancy: "VAGA",
        selection: "SELEÇÃO",
        createdBy: "CRIADO POR",
        createdAt: "CRIADO EM",
        site: "SITE",
        owner: "Responsavel",
        role: "Cargo",
        area: "ÁREA",
        manager: "Gestor",
        noInformation: "Não informado",
        mandatory: "Obrigatario",
        actions: "AÇÕES",
        job: "CARGO",
        current: "ETAPA",
        result: "RESULTADO",
        applyAt: "APLICOU EM",
        status: "STATUS",
        name: "NOME",
        cpf: "CPF",
        enrollment: "MATRÍCULA",
        scheduling: "AGENDAMENTO",
        scheduleTime: "AGENDAMENTO",
        locality: "LOCAL",
        note: "OBSERVAÇÃO",
      },
    },
    vacancyCreate: {
      createVacancy: "Cadastro de nova vaga",
      editVacancy: "Editando vaga",
      useBankOfTalents: "Utilizar banco de talentos?",
      actionCandidate: "Selecione a ação:",
      sharedDisclaimer:
        "Utilize uma das opções abaixo para compartilhar a vaga criada.",
      linkVacancy: "link da vaga",
      socialMedia: "Mídias sociais:",
      sharedVacancy: "Divulgação da vaga",
      questionBankOfTalents: `Utilizando ele, o sistema Talentos ${theme.words.nameMark} verificará se existem pessoas que se enquadram na oportunidade para aplicação.`,
      yesDiv: "Sim, e divulgar as oportunidades.",
      noDiv: "Sim, e não divulgar.",
      fields: {
        typeForm: {
          internal: "Interna",
          external: "Externa",
        },
        kindVaga: {
          bdTalents: "Banco de talentos",
          existentVacancy: "Vaga existente",
          promotion: "Promoção",
        },
      },
    },
    NewCandidatesVacancy: {
      addCandidate: "Adicionar colaborador na vaga",
      title:
        "Após validação da exceção, informe os dados do colaborador para inclui-lo na vaga. Atenção: Ao prosseguir com essa ação, você estará confirmando a inclusão de um colaborador sem passar pelos filtros de elegibilidade no sistema, conforme procedimento padrão. ",
      type: "Realizar inclusão por:",
      CPF: "CPF",
      matricula: "Matricula",
      justification:
        "Justificativa (Informe abaixo o motivo da exceção para inclusão do colaborador na vaga)",
      add: "Adicionar",
    },
    vacancyShow: {
      statusApprove: "Aprovado",
      declined: "Reprovados",
      declinedCandidate: "Vaga  |  Reprovado",
      approvedCandidate: "Aprovado",
      statusFinished: "Finalizado",
      statusInProgress: "Etapas Em Andamento",
      hired: "Contratado / Promovido",
      schedules: "Etapa | Agendamentos",
      inBankOfTalent: "Banco de talentos",
      removed: "Removido da Vaga",
      addCandidate: "Adicionar colaboradores",
      trackStage: "Etapa atual da Trilha:",
      changeTime: "Troca de horários",
      showTrack: "VER TRILHA",
      tableStatus: {
        statusCandidate: "Colaborador",
        statusApprove: "aprovado",
        statusDecline: "reprovado",
      },
    },
    bankOfTalents: {
      currentBanckOfTalent: "Vigentes",
      removed: "Removidos",
      declinedCandidate: "Reprovado",
      approvedCandidate: "Aprovado",
      statusFinished: "Finalizado",
      statusInProgress: "Em andamento",
      inBankOfTalent: "Banco de talentos",
      addCandidate: "Adicionar colaboradores",
      trackStage: "Etapa atual da Trilha:",
      changeTime: "Troca de horários",
      showTrack: "VER TRILHA",
      tableStatus: {
        statusCandidate: "Colaborador",
        statusApprove: "aprovado",
        statusDecline: "reprovado",
      },
      columns: {
        cargo: "Cargo",
        site: "Site",
        typevacancy: "Tipo vaga",
        idvacancy: "Id vaga",
        responsible: "Responsavel",
        dateinclusion: "Data de inclusão",
        daysinbank: "Dias no banco",
        vencimento: "Vencimento",
        cpf: "CPF",
        name: "Nome",
        chapa: "Chapa",
        reasonnottopromote: "Motivo não promoção",
        robbyson: "Robbyson",
      },
    },
    schedule: {
      addTimer: "Adicionar outra faixa",
      amountOfVacancies: "Quantidade de vagas da faixa",
      startHour: "Início do expediente",
      endHour: "Fim do expediente",
    },
    manager: {
      perfis: "Perfis",
      administradores: "Usuários",
      btnincluirperfil: "Incluir",
      podeeditaretapas: "Pode editar etapas",
      permissoesperfil: "Permissões do Perfil : ",
      permissaosite: "Limite por site",
      cadastroperfil: "Lista de Perfis",
      nomeprofile: "Nome do perfil",
      id: " ID",
      habilitar: "Habilitar",
      site: "Site",
      nomeAdmin: "Nome",
      Perfil: "Perfil",
      BuPosition: "Posição da Bu",
      Tax: "Taxa informada",
      Project: "Projeto",
      WorkLoc: "Local de Trabalho",
      unidade: "Unidade",
      unidades: "Permissões por Unidade",
      autorizado: "Autorizado",
      profilesector: "Permissão por seção",
      newUser: "Cadastro de novo usuário",
      registrationNumber: "Matricula",
      search: "Buscar",
      name: "Nome",
      email: "E-mail",
      createUser: "Novo Usuário",
      IsAdministrator: "Pode acessar configuração",
      canAcessPlug: "Pode acessar Journey",
      canAcessTalentos: "Pode acessar Start",
      deletar: "Deletar",
      edit: "Editar",
      acessoPlug: "Acesso ao Journey",
      acessoTalentos: "Acesso ao Talentos",
      actions: "Ações",
      administrator: "Acesso a configuração",
      realmenteDesejaDeletar: "Realmente deseja deletar?",
      selectBenefit: "Benefícios",
      selectPayment: "Pagamentos",
    },
    sharedItems: {
      Mandatory: {
        title: "As informações destacadas são obrigatórias!",
      },
      myProfile: {
        ModalEmailChange: {
          title: "Alteração no E-mail",
          info: "Devido a modificação do e-mail, realize login novamente para validar a alteração.",
          continue: "Continuar",
        },
        ModalNecessaryDataFilled: {
          title: "Informações Pessoais",
          info: "Verifique se todas as informações referentes ao colaborador foram devidamente preenchidas antes de avançar com o processo de admissão.",
          continue: "Continuar",
        },
        InfoPersonal: {
          title: "Informações de Admissão",
        },
      },
      endContractData: "Data fim do contrato",
      days: "Dias",
      question: "Pergunta",
      back: "VOLTAR",
      bankoftalets: "ENVIAR BANCO TALENTOS",
      hire: "CONTRATAR / PROMOVER",
      returnForAnalysis: "RETORNAR PARA ANALISE",
      add: "ADICIONAR",
      disabled: "desativar",
      enable: "ativar",
      email: "E-mail",
      levelTest: "Nivel do teste",
      coligada: "Coligada",
      operation: "Operação",
      completeAdmission: "concluir a admissão",
      save: "SALVAR",
      savePartial: "SALVAR PARCIAL",
      import: "IMPORTAR",
      saveImport: "SALVAR",
      publishVacancy: "PUBLICAR VAGA",
      publishVacancyBanner: "Publicação da vaga",
      shareVacancy: "COMPARTILHAR",
      selectImage:
        "Selecione uma imagem que represente o perfil de colaborador para a vaga:",
      affiliateRails: "ASSOCIAR TRILHA",
      viewRails: "Visualizar trilha",
      affiliateRailsDown: "Associar trilha",
      stats: "Status",
      name: "Nome",
      level: "Nível",
      job: "Cargo",
      mealBalance: "Vale Refeição",
      indicator: "Indicador",
      kind: "Tipo",
      description: "Descrição",
      time: "Horário",
      confirme: "Confirmar",
      edit: "SALVAR EDIÇÃO",
      cancel: "CANCELAR",
      next: "PRÓXIMO",
      kindTest: "Tipo de teste",
      kindTrainner: "Tipo de treinamento",
      remove: "remover",
      view: "Visualizar",
      upload: "Upload Documento",
      viewEdit: "Alterar Status",
      search: "Buscar",
      selection: "Seleção",
      vacancy: "Vaga",
      street: "Rua",
      neighborhood: "Bairro",
      streetNumber: "Número",
      role: "Cargo",
      salary: "Salário",
      operation: "Operação",
      companyName: "Nome da viação:",
      ticketValue: "Valor da Passagem",
      amountOfTickets: "Quantidade De Passagens",
      formEspecificInformation: "Informações específicas",
      formVacancyInformation: "Informações da vaga",
      formSelectionInformation: "Informações de seleção",
      formCandidate: "Características do colaborador",
      formTimeOfWork: "Faixa de horário de trabalho",
      formInternal: "Informações internas",
      performanceIndicator: "Indicador de Performance",
      addIndicator: "ADICIONAR INDICADOR",
      avaliation: "Avaliação",
      timeTest: "Tempo para o teste",
      candidateForVacancy: "Colaborador para a  vaga:",
      mediumQuestionTime: "Tempo médio por pergunta",
      addQuestion: "Adicionar pergunta",
      yes: "Sim",
      no: "Não",
      useRails: "USAR TRILHA",
      editRails: "EDITAR TRILHA",
      at: "Em",
      createdBy: "Criado por",
      close: "FECHAR",
      until: "até",
      delete: "deletar",
      removeQuestionTitle: "Remover Pergunta",
      removeQuestion: "Deseja remover esta pergunta ?",
      openVacancy: "Aberta",
      closeVacancy: "Fechada",
      filter: "Filtrar",
      registers: "Registros",
      code: "Código:",
      enrollment: "Matrícula",
      periode: "Período",
      manager: "Gestor:",
      status: "Status",
      clear: "Limpar",
      clearUper: "LIMPAR",
      apply: "Aplicar",
      site: "Site",
      applyedCandidates: "Colaboradores aplicados",
      openVagas: "Número de vagas disponível",
      stage: "Etapa atual",
      historic: "Histórico",
      sendMessage: "Enviar mensagem",
      jobCandidate: "Colaborador para cargo:",
      area: "Área:",
      managerInfo: "Gestor:",
      candidateStage: "Etapa do colaborador:",
      confirmApprove: "Confirmar aprovação",
      bankofTalents: "Banco de talentos",
      newCandidate: "Cadastrar colaborador",
      declineApprove: "Confirmar reprovação",
      pendingApprove: "Confirmar pendência",

      confirmRemoval: "Confirmar remoção",

      keep: "Continuar",
      nameRole: "Cargo",
      nivel: "Nível",
      months: "Mês",
      stagesTitle: {
        default: "Dados básicos",
        aboutYou: "Sobre você",
        carrier: "Carreira",
        formation: "Formação",
        goal: "Objetivos",
        test: "Teste",
      },
      about: "Sobre",
      carrier: "Carreira",
      formation: "Formação",
      goal: "Objetivos",
      attach: "Anexos",
      CPF: "CPF",
      CNPJ: "CNPJ",
      RG: "RG",
      birth: "Nascimento",
      phone: "Telefone",
      workingDays: "Dias Úteis",
      nationality: "Nacionalidade",
      state: "Estado",
      city: "Cidade",
      neighborhood: "Bairro",
      street: "Logradouro",
      Complement: "Complemento",
      zipCode: "CEP",
      sendFeedBack: "Enviar feedback ...",
      enableCandidateAnswer: "Habilitar resposta do(s) colaborador(s).",
      dateOfTest: "Data de realização",
      result: "Resultado",
      minimumResult: "Resultado Mínimo",
      evaluate: "Avaliar",
      evaluateApproved: "Aprovado",
      evaluateReproved: "Reprovado",
      evaluateCurrent: "Atual",
      evaluateportfolio: "Avaliar portfolio",
      evaluatewait: "Aguardando",
      manualEvaluatewait: "Aprovação manual",
      evalueteTest: "Visualizar teste",
      applyToAllSameTime:
        "Aplicar as mesmas datas e horários para todos os canditos",
      interviewCandidates: "Entrevistar colaboradores",
      medicExame: "Marcar exame médico",
      icsFile: "BAIXAR .ICS",
      scheduleCandidates: "Agendar colaboradores",
      selectVacancy: "Selecione a vaga",
      generatePreview: "Visualizar preview",
      confirmPublication: "Deseja confirmar a publicação dessa vaga ?",
      confirmDeleteVacancy: "Deseja cancelar essa vaga?",
      deleteVacancy: "Cancelar vaga",
      createQuestions: "Criar perguntas",
      finish: "Finalizar",
      applyAt: "Aplicou em",
      startDate: "Data Inicial",
      endDate: "Data Final",
      startDateApplied: "Data Inicial - APLICOU EM",
      endDateApplied: "Data Final - APLICOU EM",
      schedulingDate: "Data Agendamento",
      pending: "Pendenciar",
      empty: "",
      selectSolicitation: "Selecione a solicitação",
      showfeedback: "Este feedback será exibido para o(s) colaborador(s)",
      notFeedback: "Este feedback não será exibido para o(s) colaborador(s)",
      selectStage: "Selecione a etapa",
      selectAction: "Selecione a ação",
      exportCandidate: "Exportar",
      actualRail: "Trilha atual",
      clearFilter: "Limpar Filtro",
      createSolicitation: "Criar Solicitação",
      createAlert:"Criar Alerta",
      AddDocument: "Incluir documentação",
      loadMore: "Carregar Mais",
      releaseForSignature: "Liberar Assinaturas",
      registerBenefits: "Cadastrar Beneficios",
      registerBenefitsBulk: "Cadastrar Beneficios Em Massa",
      exportBenefits: "Exportar Beneficios",
      exportPayments: "Exportar Pagamentos",
      registerPayments: "Cadastrar Pagamentos",
      typeBenefits: "Tipo de Beneficio",
      groupByVacancy: "Agrupar Por Vaga",
      nomeQueGostariaDeSerChamado: "Nome que gostaria de ser chamado",
      maritalStatus: "Estado civil",
      numberOfChildren: "Número de filhos",
      typeDisability: "Tipo de deficiência",
      localUnit: "Preferência de local de trabalho",
      issuingEntity: "Orgão emissor (sigla)",
      issuingStateRG: "Estado emissor",
      dateEmition: "Data de emissão",
      homeState: "Estado natal",
      homeCity: "Cidade natal",
      phone1: "Telefone Principal",
      phone2: "Telefone Adicional",
      doYouHaveADisability: "Possui deficiência?",
      firstJob: "Primeiro emprego ?",
      estadoCTPS: "Estado Emissor CTPS",
      fixCode: "Codigo fixo",
      eventCode: "Codigo do Evento",
      determinedDeadline: "Prazo Determinado",
      codeSEFIP: "Codigo SEFIP",
      codeSindicato: "Codigo Sindicato",
      reasonAdmission: "Motivo da admissão",
      cep: "CEP",
      kindOfStreet: "Tipo de logradouro",
      address: "Endereço",
      number: "Número",
      daysFirstContract: "Quantidade de dias primeiro contrato",
      daysSecondContract: "Quantidade de dias segundo contrato",
      typeContract: "Tipo de contrato",
      jorney: "Jornada",
      timeJorney: "Horário",
      contractPartialRegime: "Contrato de trabalho em regime parcial",
      typeRegimeJorney: "Tipo de regime de jornada",
      situation: "Situação",
      pointRecord: "Registra ponto",
      registrationIntegration: " Integração cadastro",
      registrationHierarchy: " Hierarquia cadastro",
      dentalPlan: "Plano Odontologico",
      lifeInsurance: "Seguro de vida",
      medicalAssistance: "Assistência Médica",
      medicalAssistanceBradesco: "Assistência Médica Bradesco",
      medicalAssistanceUnimed: "Assistência Médica Unimed",
      dentalPlanSulamerica: "Plano Odontologico Sulamerica",
      snack: "Refeição",
      food: "Alimentação",
      complement: "Complemento",
      typeOfNeighborhood: "Tipo de bairro",
      typeStreet: "Tipo de logradouro",
      country: "País",
      anyOfYourFamilyMembersWhoHaveHeldOrHeldOfficeOrFunctionInTheLastFiveYearsInBrazilOrAbroad:
        "Alguem de sua família que exerça ou exerceu cargo ou função pública e/ou política, nos últimos 5 (Cinco) anos, no Brasil ou no exterior?",
      doYouHaveAnyRelativesWhoWorkHere:
        "Você tem algum parente que trabalha na Vinci?",
      office: "Cargo",
      genre: "Sexo",
      schoolLevel: "Escolaridade",
      codTipoFuncionario: "Tipo funcionario",
      coligadaCandidato: "Coligada",
      dataAdmissao: "Data admissão",
      dataBase: "Data base",
      motivoAdmissao: "Motivo Admissão",
      codFuncao: "Código Função",
      funcao: "Função",
      codSecao: "Código Seção",
      secao: "Seção",
      colorRace: "Cor / Raça",
      genreDescription: "Genero",
      pisParaFgts: "PIS para FGTS",
      dataCadastroPis: "Data cadastro PIS",
      salario: "Salário",
      codTomador: "Código tomador",
      tomador: "Tomador",
      codBancoPis: "Código banco PIS",
      motherName: "Nome da mãe",
      fatherName: "Nome do pai",
      spouseName: "Nome do cônjuge/companheiro",
      numberOfDependents: "Número de dependentes",
      nameDependent: "Nome filho(a)/enteado(a)",
      genreOfDependents: "Sexo do filho(a)/enteado(a)",
      addDependent: "Adicionar filho",
      addTransport: "Adicionar Vale Transporte",
      kinship: "Parentesco",
      formationQualification: "Formação/Qualificação",
      dependentForIncomeTax: "Dependente para IR?",
      dependentFamilySalary: "Dependente de Salário Família?",
      motherCpf: "CPF da mãe",
      fatherCpf: "CPF do pai",
      dependentCpf: "CPF do dependente",
      dateBirthSpouse: "Nascimento do cônjuge/companheiro",
      spouseInformation: "Conjuge ou Companheiro ?",
      spouseGenre: "Sexo do Conjuge",
      spouseCpf: "CPF do Cônjuge",
      spouseIrrf: "Conjuge Dependente de IR ?",
      joinTransportVoucher: "Deseja utilizar vale transporte?",
      pis: "PIS",
      bank: "Banco",
      agency: "Agência",
      count: "Conta",
      alternativeBank: "Banco alternativo",
      alternativeAgency: "Agência alternativa",
      alternativeCount: "Conta alternativa",
      alternativeCountType: "Tipo da Conta Alternativa",
      CountType: "Tipo da Conta",
    },
    modalUpload: {
      send: "Enviar",
      close: "Fechar",
      selectFile: "Selecinar arquivo",
      selectOption: "Selecione uma opção",
      cpf: "CPF",
      document: "Documento",
      name: "Nome",
      selectDocumet: "Enviar Documento",
    },
    rails: {
      title: "Lista de Trilhas",
      newRails: "Nova trilha",
      addStageTorails: "ADICIONAR NOVA ETAPA",
      addStageTorailsList: "Adicionar  etapa",
      searchStage: "Buscar etapa",
      kindStage: "Tipo de etapa",
      kindTest: "Tipo de teste",
      levelTest: "Nível do teste",
      timeToTest: "Tempo para teste",
      spendTimeInTest: "Tempo gasto",
      timeToQuetionTest: "Perguntas / Tempo médio",
      level: "Nível",
      avaliation: "Avaliação",
      numberOfQuestion: "Nº de perguntas",
      maxTime: "Tempo máximo",
      assayKind: "Tipo de análise",
      trainnerKind: "Tipo de treinamento",
      exameKind: "Tipo de exame",
      ambientationKind: "Tipo de ambientação",
      test: "Teste",
      medic: "Exame médico",
      trainner: "Treinamento",
      assay: "Análise",
      admition: "Admissão",
      ambientation: "Ambientação",
      feedBack: "FeedBack",
      questions: "Tempo do teste",
      existiRails: "Já existem trilhas para o cargo",
      noRails: "Não existem trilhas para o cargo",
      stageDetails: "Detalhes da etapa",
      desableRails: "Deseja alterar o status dessa trilha ?",
      associationVacancy: "Associar trilha",
      preAdmition: "Pré admissão",
      analyze: "Análise",
      editRails: "Editar trilha",
    },
    menuPlugBar: {
      dashboard: "Dashboard",
      start: "Inicial",
      documents: "Documentação",
      contracts: "Contratos",
      stage: "Etapas",
      candidates: "Colaboradores",
      rails: "Trilhas",
      selectVacancy: "Selecione a vaga",
      managers: "Administradores",
      pendingSignature: "Pendente assinatura",
      doubts: "Solicitações",
      doubtsManager: "Solicitações Gestor",
      enviarDocumentos: "Enviar Documentos",
      benefits: "Beneficios",
      payments: "Demais Pagamentos",
    },
    menuManagerBar: {
      users: "Usuários",
      modules: "Módulos",
      profiles: "Perfis",
      settings: "Cadastros",
      parameters: "Parametros",
    },
    menuBankOfTalentsBar: {
      users: "Usuários",
      BankOfTalents: "Banco de Talentos",
    },
    duvidasCandidate: {
      nomeCandidato: "Nome",
      motivoDoContato: "Motivo do contato",
      status: "Status",
      descricao: "Descrição",
      dataDoChamado: "Data abertura",
      detalhes: "DETALHES",
      responder: "Responder",
    },
    duvidasCandidateList: {
      id: "Id",
      nomeCandidato: "Nome",
      motivoDoContato: "Motivo do contato",
      localTrabalho: "Local De Trabalho",
      status: "Status",
      descricao: "Descrição",
      slaStatus: "Status SLA",
      duracao: "Duração do Atendimento",
      dataDoChamado: "Data",
      type: "Tipo",
    },
    plugCandidatesList: {
      cpf: "CPF",
      placeOfWork: "Local De Trabalho",
      status: "Status",
      completedAdmission: "Adm Concluiu Admissao",
      DataCompletAdmission: "Data Concluiu Admissao",
      nomeCandidato: "Nome",
      email: "Email",
      analisar: "Analisar",
      filterCpf: "CPF",
      document: "Documento",
    },
    benefitsCandidateList: {
      id: "Chapa",
      name: "Nome",
      competenceMonth: "Mês De Competência",
      absence: "Ausências",
      businessDays: "Dias Uteis",
      transportTicket: "Vale Transporte",
      mealTicket: "Vale Refeição",
      export: "Exportar",
      paymentType: "Tipo De Pagamento",
      paymentValue: "Valor Pagamento",
      identifier: "Identificador",
      creationTime: "Criação",
      updateTime: "Data Atualização",
    },
    documentsCandidateList: {
      vaga: "Vaga",
      cpf: "CPF",
      filterCpf: "CPF",
      filterIdVaga: "Código da vaga",
      filterLocation: "Local trabalho",
      nomeCandidato: "Nome",
      documentosEnviados: "Documentos Enviados",
      status: "Status",
      analisar: "Analisar",
      draft: "Rascunhos",
      open: "Abertas",
      newVacancy: "Nova Vaga",
      editVacancy: "Editar Vaga",
      finished: "Finalizadas",
      documents: "Documentos",
      document: "Documento",
      sla: "SLA",
      duration: "Duração",
      columns: {
        code: "Código",
        vacancy: "VAGA",
        selection: "SELEÇÃO",
        createdBy: "CRIADO POR",
        createdAt: "CRIADO EM",
        role: "Cargo",
        idDocument: "ID",
        typeDocument: "Tipo Documento",
        document: "Documento",
        action: "Ação",
        area: "ÁREA",
        manager: "Gestor",
        noInformation: "Não informado",
        mandatory: "Obrigatario",
        actions: "AÇÕES",
        job: "CARGO",
        current: "ETAPA",
        result: "RESULTADO",
        applyAt: "APLICOU EM",
        status: "STATUS",
        name: "NOME",
      },
      filterByGroup: "Local de trabalho",
    },
    bankOfTalents: {
      admin: "Responsavel",
      job: "Cargo",
    },
    benefits: {
      nome: "Beneficio",
    },
    contracts: {
      confirmSendKitMessage: "Confirmar Kit",
      confirmSendMessage: "Confirmar Envio",
      sendConfirmMsgTitleKit: "Alteração de kit do colaborador",
      sendConfirmMsgTitleBenefit: "Alteração dos valores de beneficio",
      sendConfirmMsgTitle: "Envio de mensagem de aceita para colaborador",
      sendConfirmMsgDescription:
        "Digite a mensagem com as informações para o colaborador",
      View_default_contract: "Contrato",
      View_assigned_contract: "Contrato assinado",
      cpf: "CPF",
      filterCpf: "CPF",
      motivoDoContato: "Motivo do contato",
      filterIdVaga: "Código da vaga",
      filterLocation: "Local trabalho",
      statusEtapa: "Status da etapa",
      nomeCandidato: "Nome",
      titleVacancy: "Vaga:",
      documentosEnviados: "Documentos Enviados",
      status: "Status",
      statusSla: "Status SLA",
      analisar: "Analisar",
      draft: "Rascunhos",
      open: "Abertas",
      newVacancy: "Nova Vaga",
      editVacancy: "Editar Vaga",
      finished: "Finalizadas",
      documents: "Documentos",
      columns: {
        code: "Código",
        vacancy: "VAGA",
        selection: "SELEÇÃO",
        createdBy: "CRIADO POR",
        createdAt: "CRIADO EM",
        role: "Cargo",
        idDocument: "ID",
        typeDocument: "Tipo Documento",
        document: "Documento",
        action: "Ação",
        area: "ÁREA",
        manager: "Gestor",
        noInformation: "Não informado",
        mandatory: "Obrigatario",
        actions: "AÇÕES",
        job: "CARGO",
        current: "ETAPA",
        result: "RESULTADO",
        applyAt: "APLICOU EM",
        status: "STATUS",
        name: "NOME",
      },
    },
  },
};

export const months = [
  { label: "Jan", value: 1 },
  { label: "Fev", value: 2 },
  { label: "Mar", value: 3 },
  { label: "Abr", value: 4 },
  { label: "Mai", value: 5 },
  { label: "Jun", value: 6 },
  { label: "Jul", value: 7 },
  { label: "Ago", value: 8 },
  { label: "Set", value: 9 },
  { label: "Out", value: 10 },
  { label: "Nov", value: 11 },
  { label: "Dez", value: 12 },
];

export const kindStages = [
  { name: "Dados cadastrais", id: 0, item: "data", label: "stageList.data" },
  { name: "Teste", id: 1, label: "rails.test" },
  { name: "Treinamento", id: 2, item: "trainnerKind", label: "rails.trainner" },
  { name: "Analise", id: 3, item: "assayKind", label: "rails.analyze" },
  { name: "Exame Médico", id: 4, item: "exameKind", label: "rails.medic" },
  { name: "Admissão", id: 5, label: "rails.admition" },
  {
    name: "Ambientação",
    id: 6,
    item: "ambientationKind",
    label: "rails.ambientation",
  },
  { name: "FeedBack", id: 7, label: "rails.feedBack" },
  { name: "Pré admissão", id: 8, label: "rails.preAdmition" },
  { name: "Pré admissão", id: 8, label: "rails.preAdmition" },
  {
    name: "Aprovação final",
    id: 99999999,
    item: "data",
    label: "stageList.data",
  },
];

export const StatusStages = [
  { name: "Aguardando", id: 0 },
  { name: "Em Análise", id: 1 },
  { name: "Aprovado Por Pontuacao", id: 2 },
  { name: "Aprovado Manualmente", id: 3 },
  { name: "Cancelado", id: 4 },
  { name: "Reprovado Por Pontuação", id: 5 },
  { name: "Tempo Exirado", id: 6 },
  { name: "Conexão Perdida", id: 7 },
  { name: "Reprovado Manualmente", id: 8 },
  { name: "Pronto para validação", id: 9 },
  { name: "Pendenciado", id: 10 },
  { name: "Reprovado por inelegibilidade", id: 11 },
  { name: "Concluido com pendência de processamento", id: 12 },
  { name: "Redação iniciada", id: 13 },
  { name: "Redação finalizada", id: 14 },
  { name: "Redação concluido com pendência de processamento", id: 15 },
  { name: "Contrato finalizado", id: 16 },
  { name: "Divergencia detectada pelo colaborador", id: 17 },
  { name: "Desistente", id: 18 },
  { name: "RemovidoPeloRecrutador", id: 19 },
];

export const StatusVacancy = [
  { name: "Em Andamento", id: 0 },
  { name: "Aprovado", id: 1 },
  { name: "Reprovado", id: 2 },
  { name: "ContratadoPromovido", id: 4 },
  { name: "EmBancoHoras", id: 5 },
  { name: "RemovidoPeloRecrutador", id: 12 },
];

export const schedulingCandidateAction = { name: "AGENDAMENTO", id: 2 };
export const pendingCandidateAction = { name: "PENDENCIAR", id: 3 };
export const bancoTalentosCandidateAction = {
  name: "BANCO DE TALENTOS",
  id: 4,
};
export const contratarCandidateAction = { name: "CONTRATAR/PROMOVER", id: 5 };
export const returnForAnalysisAction = { name: "RETORNAR PARA ANALISE", id: 7 };

export const candidatesActions = [
  { name: "APROVAR ETAPA", id: 0 },
  { name: "REPROVAR ETAPA", id: 1 },
  { name: "REMOVER DA VAGA", id: 6 },
];

export const candidatesRefused = [{ name: "APROVAR ETAPA", id: 0 }];
export const candidatesApproved = [
  { name: "BANCO DE TALENTOS", id: 4 },
  { name: "CONTRATAR/PROMOVER", id: 5 },
];

export const renderNumberOfSelected = (many) =>
  `Todos os colaboradores ( ${many} selecionad${many > 1 ? "os" : "o"} )`;
export const questionApproveCandidate = (many) =>
  `Tem certeza que deseja aprovar os ${many} colaboradores selecionados?`;
export const questionReproveCandidate = (many) =>
  `Tem certeza que deseja reprovar os ${many} colaboradores selecionados?`;
export const questionPendingCandidate = (many) =>
  `Tem certeza que deseja pendenciar os ${many} colaboradores selecionados?`;
export const questionRemoveCandidate = (many) =>
  `Tem certeza que deseja remover os ${many} colaboradores selecionados?`;
export const questionReturnForAnalysisCandidate = (many) =>
  `Tem certeza que deseja reornar par o status "Em Analise" os ${many} colaboradores selecionados?`;
export const VACANCY_OPTIONS = [
  { name: "Interna", kind: "internal" },
  { name: "Externa", kind: "external" },
  { name: "Banco de Talentos", kind: "talents" },
  { name: "Vaga Existente", kind: "existJob" },
  { name: "Promoção", kind: "promotion" },
];

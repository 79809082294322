import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  vacancyCandidatesRequest: ["filter"],
  vacancyCandidatesRequestSuccess: ["vacancies", "isLoading"],
  vacancyCandidatesRequestFail: ["isLoading", "error"],

  candidatesFromRequest: ["payload", "page"],
  candidatesFromSuccess: ["candidates", "isLoading"],
  candidatesFromFail: ["isLoading", "error"],

  candidatesManagerRequest: [
    "candidates",
    "kindOfAction",
    "vacancyId",
    "stageId",
    "stageType",
  ],
  candidatesManagerSuccess: ["isLoading"],
  candidatesManagerFail: ["isLoading", "error"],

  candidatesDispatchManagerRequest: [
    "candidates",
    "kindOfAction",
    "vacancyId",
    "enabledSendToCandidates",
    "feedback",
    "stageId",
  ],
  candidatesDispatchManagerSuccess: ["isLoading"],
  candidatesDispatchManagerFail: ["isLoading", "error"],

  candidateShowRequest: ["candidateId", "vacancyId"],
  candidateShowSuccess: ["candidate", "isLoading"],
  candidateShowFail: ["error"],

  candidatesScheduleEnterviewRequest: [
    "candidates",
    "vacancyId",
    "kindOfAction",
    "stageId",
  ],
  candidatesScheduleEnterviewSuccess: ["isLoading"],
  candidatesScheduleEnterviewFail: ["isLoading", "error"],

  candidatesExportRequest: ["candidates", "vacancyId"],
  candidatesExportSuccess: ["payload"],
  candidatesExportFail: ["error"],

  candidatesAddCandidateRequest: ["candidate", "vacancyId"],
  candidatesAddCandidateSuccess: ["payload"],
  candidatesAddCandidateFail: ["error"],

  candidatesCreateCandidateRequest: ["candidate", "vacancyId"],
  candidatesCreateCandidateSuccess: ["payload", "isLoading"],
  candidatesCreateCandidateFail: ["error", "isLoading"],

  candidatesCreateCandidateBulkRequest: ["candidatesBulk", "vacancyId"],
  candidatesCreateCandidateBulkSuccess: ["payload"],
  candidatesCreateCandidateBulkFail: ["error"],

  candidatesGetCandidatesListRequest: ["payload"],
  candidatesGetCandidatesListSuccess: ["payload", "isLoading"],
  candidatesGetCandidatesListFail: ["error"],

  informacoesCandidatoRequest: ["payload"],
  informacoesCandidatoRequestSuccess: ["candidate", "isLoading"],
  informacoesCandidatoRequestFail: ["error"],

  candidateProfileUpdateRequest: ["payload"],
  candidateProfileUpdateSuccess: ["payload", "isLoading"],
  candidateProfileUpdateFail: ["isLoading"],

  candidateAdmissionRequest: ["payload"],
  candidateAdmissionSuccess: ["payload", "isLoading"],
  candidateAdmissionFail: ["isLoading"],

  candidateRegisterVacancyRequest: ["payload"],
  candidateRegisterVacancySuccess: ["payload", "isLoading"],
  candidateRegisterVacancyFail: ["isLoading", "error"],

  candidateSimpleRequest: [],
  candidateSimpleSuccess: ["payload", "isLoading"],
  candidateSimpleFail: ["error", "isLoading"],

  candidateRequireFields: [""],
  candidateUnrequireFields: [""],

  informacoesCandidatoRmTomadorRequest: ["payload"],
  informacoesCandidatoRmTomadorRequestSuccess: ["candidate", "isLoading"],
  informacoesCandidatoRmTomadorRequestFail: ["error"],

  informacoesCandidatoRmTipoFuncionarioRequest: ["payload"],
  informacoesCandidatoRmTipoFuncionarioRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoRmTipoFuncionarioRequestFail: ["error"],

  informacoesCandidatoRmSecaoRequest: ["payload"],
  informacoesCandidatoRmSecaoRequestSuccess: ["candidate", "isLoading"],
  informacoesCandidatoRmSecaoRequestFail: ["error"],

  informacoesCandidatoRmFuncaoByColigadaRequest: ["payload"],
  informacoesCandidatoRmFuncaoByColigadaRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoRmFuncaoByColigadaRequestFail: ["error"],

  informacoesCandidatoColigadaPerfilRequest: ["payload"],
  informacoesCandidatoColigadaPerfilRequestSuccess: ["candidate"],
  informacoesCandidatoColigadaPerfilRequestFail: ["error"],

  informacoesCandidatoColigadaRequest: ["payload"],
  informacoesCandidatoColigadaRequestSuccess: ["candidate", "isLoading"],
  informacoesCandidatoColigadaRequestFail: ["error"],

  informacoesCandidatoMotivoAdmissaoPorColigadaRequest: ["payload"],
  informacoesCandidatoMotivoAdmissaoPorColigadaRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoMotivoAdmissaoPorColigadaRequestFail: ["error"],

  informacoesCandidatoSindicatoPorColigadaRequest: ["payload"],
  informacoesCandidatoSindicatoPorColigadaRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoSindicatoPorColigadaRequestFail: ["error"],

  informacoesCandidatoHorarioPorColigadaRequest: ["payload"],
  informacoesCandidatoHorarioPorColigadaRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoHorarioPorColigadaRequestFail: ["error"],

  informacoesCandidatoPlanoDentalPorColigadaRequest: ["payload"],
  informacoesCandidatoPlanoDentalPorColigadaRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoPlanoDentalPorColigadaRequestFail: ["error"],

  informacoesCandidatoSeguroPorColigadaRequest: ["payload"],
  informacoesCandidatoSeguroPorColigadaRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoSeguroPorColigadaRequestFail: ["error"],

  informacoesCandidatoAssistenciaMedicaPorColigadaRequest: ["payload"],
  informacoesCandidatoAssistenciaMedicaPorColigadaRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoAssistenciaMedicaPorColigadaRequestFail: ["error"],

  informacoesCandidatoAssistenciaMedicaBradescoPorColigadaRequest: ["payload"],
  informacoesCandidatoAssistenciaMedicaBradescoPorColigadaRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoAssistenciaMedicaBradescoPorColigadaRequestFail: [
    "error",
  ],

  informacoesCandidatoAssistenciaMedicaUnimedPorColigadaRequest: ["payload"],
  informacoesCandidatoAssistenciaMedicaUnimedPorColigadaRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoAssistenciaMedicaUnimedPorColigadaRequestFail: ["error"],

  informacoesCandidatoPlanoDentalSulamericaPorColigadaRequest: ["payload"],
  informacoesCandidatoPlanoDentalSulamericaPorColigadaRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoPlanoDentalSulamericaPorColigadaRequestFail: ["error"],

  informacoesCandidatoAlimentacaoPorColigadaRequest: ["payload"],
  informacoesCandidatoAlimentacaoPorColigadaRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoAlimentacaoPorColigadaRequestFail: ["error"],

  informacoesCandidatoRefeicaoPorColigadaRequest: ["payload"],
  informacoesCandidatoRefeicaoPorColigadaRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoRefeicaoPorColigadaRequestFail: ["error"],

  informacoesCandidatoHierarquiaCadastroFluigRequest: ["payload"],
  informacoesCandidatoHierarquiaCadastroFluigRequestSuccess: [
    "candidate",
    "isLoading",
  ],
  informacoesCandidatoHierarquiaCadastroFluigRequestFail: ["error"],

  candidatesPasswordResetRequest: ["candidates", "vacancyId"],
  candidatesPasswordResetSuccess: ["payload"],
  candidatesPasswordResetFail: ["error"],

  releaseCancellationForCandidatesRequest: ["payload"],
  releaseCancellationForCandidatesSuccess: ["stages", "isLoading"],
  releaseCancellationForCandidatesFail: ["isLoading", "error"],

  candidateInformationRmSecaoListRequest: ["payload"],
  candidateInformationRmSecaoListSuccess: ["candidate", "isLoading"],
  candidateInformationRmSecaoListFail: ["error"],

  candidateDocumentUpdateRequest: ["payload"],
  candidateDocumentUpdateSuccess: ["isLoading"],
  candidateDocumentUpdateFail: ["isLoading"],

  candidateExportDocumentRequest: ["payload"],
  candidateExportDocumentSuccess: ["payload", "isLoading"],
  candidateExportDocumentFail: ["isLoading"],
});

const INITIAL_STATE = {
  vacancies: [],
  isLoading: true,
  candidates: [],
  vacancy: {},
  hasMore: true,
  page: 1,
  tomador: [],
  tipoFuncionario: [],
  codSecao: [],
  funcao: [],
  horarios: [],
  planoDental: [],
  segurosDeVida: [],
  assistenciaMedica: [],
  assistenciaMedicaBradesco: [],
  assistenciaMedicaUnimed: [],
  planoDentalSulamerica: [],
  alimentacao: [],
  refeicao: [],
  registrationHierarchy: [],
  coligadaCandidato: [],
  motivoAdmissao: [],
  codeSindicato: [],
  requiredFields: true,
  updated: false,
  candidatesBulk: [],
  secaoInfo: [],
};

const vacancyCandidatesRequest = state => ({
  ...state,
  isLoading: true
});
const vacancyCandidatesRequestSuccess = (state, vacancies) => ({
  ...state,
  ...vacancies,
  isLoading: false
});
const vacancyCandidatesRequestFail = (state, error) => ({
  ...INITIAL_STATE,
  isLoading: false,
  ...error
});

const candidatesFromRequest = (state) => ({ ...state, isLoading: true });
const candidatesFromSuccess = (state, candidates) => ({
  ...state,
  ...candidates,
  isLoading: false
});
const candidatesFromRequestFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});

const candidatesManagerRequest = state => ({
  ...INITIAL_STATE,
  isLoading: true
});
const candidatesManagerSuccess = (state, vacancies, kindOfAction, stageId, stageType) => ({
  ...state,
  ...vacancies,
  ...kindOfAction,
  ...stageId,
  ...stageType,
  isLoading: false
});
const candidatesManagerFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});

const candidatesDispatchManagerRequest = state => ({
  ...state,
  isLoading: true
});
const candidatesDispatchManagerSuccess = (state, vacancies, kindOfAction) => ({
  ...state,
  ...vacancies,
  ...kindOfAction,
  isLoading: false
});
const candidatesDispatchManagerFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});

const candidateShowRequest = () => ({ ...INITIAL_STATE, isLoading: true });
const candidateShowSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
});
const candidateShowFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});
const candidatesScheduleEnterviewRequest = state => ({
  ...INITIAL_STATE,
  isLoading: true
});

const candidatesScheduleEnterviewSuccess = (
  state,
  vacancies,
  kindOfAction
) => ({});

const candidatesScheduleEnterviewFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error
});

const candidatesExportRequest = (state) => ({
  ...state,
  isLoading: true
})

const candidatesExportSuccess = (state, payload) => ({
  ...state,
  isLoading: false
})

const candidatesExportFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const candidatesAddCandidateRequest = (state) => ({
  ...state,
  isLoading: true
})

const candidatesAddCandidateSuccess = (state, payload) => ({
  ...state,
  isLoading: false
})

const candidatesAddCandidateFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const candidatesCreateCandidateRequest = (state) => ({
  ...state,
  isLoading: true
})

const candidatesCreateCandidateSuccess = (state, payload) => ({
  ...state,
  isLoading: false
})

const candidatesCreateCandidateFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})


const candidatesCreateCandidateBulkRequest = (state) => ({
  ...state,
  isLoading: true
})

const candidatesCreateCandidateBulkSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
})

const candidatesCreateCandidateBulkFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const candidatesGetCandidatesListRequest = state => ({ ...state, isLoading: true });
const candidatesGetCandidatesListSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});
const candidatesGetCandidatesListFail = (state, error) => ({ ...state, isLoading: false, error });



const informacoesCandidatoRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false,
  updated: false
})

const informacoesCandidatoRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const candidateAdmissionRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const candidateAdmissionSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false,
  updated: true
})

const candidateAdmissionFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const candidateProfileUpdateRequest = state => ({
  ...state,
  isLoading: true
});

const candidateProfileUpdateSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
  updated: true
});

const candidateProfileUpdateFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});


const candidateRegisterVacancyRequest = state => ({
  ...state,
  isLoading: true
});

const candidateRegisterVacancySuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});

const candidateRegisterVacancyFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});



const candidateSimpleRequest = state => ({
  ...state,
  isLoading: false
});

const candidateSimpleSuccess = (state, payload) => ({
  ...state,
  preAdmissionIsComplete: payload.preAdmissaoEstaCompleto,
  candidateIsInPreAdmissionStage: payload.candidatoPodeAlterarDadosPreAdmissao,
  hasPendingStage: payload.possuiEtapaPendente,
  pendingStageType: payload.tipoEtapa,
  pendingStageId: payload.etapaPendenteId,
  perfilStrength: payload.forcaPerfil,
  avatar: payload.avatar,
  currentVacancy: payload.idVagaAtual,
  isLoading: false
});

const candidateSimpleFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});


const candidateRequireFields = state => ({
  ...state,
  requiredFields: true
});

const candidateUnrequireFields = state => ({
  ...state,
  requiredFields: false
});




const informacoesCandidatoRmTomadorRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoRmTomadorRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoRmTomadorRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})




const informacoesCandidatoRmTipoFuncionarioRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoRmTipoFuncionarioRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoRmTipoFuncionarioRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})



const informacoesCandidatoRmSecaoRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoRmSecaoRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoRmSecaoRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})


const informacoesCandidatoRmFuncaoByColigadaRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoRmFuncaoByColigadaRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoRmFuncaoByColigadaRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const informacoesCandidatoColigadaPerfilRequest = (state, payload) => ({
  ...state,
  ...payload,
  // isLoading: true
})

const informacoesCandidatoColigadaPerfilRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  // isLoading: false
})

const informacoesCandidatoColigadaPerfilRequestFail = (state, error) => ({
  ...state,
  ...error,
  // isLoading: false
})


const informacoesCandidatoColigadaRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoColigadaRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoColigadaRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})


const informacoesCandidatoMotivoAdmissaoPorColigadaRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoMotivoAdmissaoPorColigadaRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoMotivoAdmissaoPorColigadaRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})



const informacoesCandidatoSindicatoPorColigadaRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoSindicatoPorColigadaRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoSindicatoPorColigadaRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})


const informacoesCandidatoHorarioPorColigadaRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoHorarioPorColigadaRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoHorarioPorColigadaRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})


const informacoesCandidatoPlanoDentalPorColigadaRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoPlanoDentalPorColigadaRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoPlanoDentalPorColigadaRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const informacoesCandidatoSeguroPorColigadaRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoSeguroPorColigadaRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoSeguroPorColigadaRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const informacoesCandidatoAssistenciaMedicaPorColigadaRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoAssistenciaMedicaPorColigadaRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoAssistenciaMedicaPorColigadaRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const informacoesCandidatoAssistenciaMedicaBradescoPorColigadaRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoAssistenciaMedicaBradescoPorColigadaRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoAssistenciaMedicaBradescoPorColigadaRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const informacoesCandidatoAssistenciaMedicaUnimedPorColigadaRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoAssistenciaMedicaUnimedPorColigadaRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoAssistenciaMedicaUnimedPorColigadaRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const informacoesCandidatoPlanoDentalSulamericaPorColigadaRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoPlanoDentalSulamericaPorColigadaRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoPlanoDentalSulamericaPorColigadaRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const informacoesCandidatoAlimentacaoPorColigadaRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoAlimentacaoPorColigadaRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoAlimentacaoPorColigadaRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})


const informacoesCandidatoRefeicaoPorColigadaRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoRefeicaoPorColigadaRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoRefeicaoPorColigadaRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})



const informacoesCandidatoHierarquiaCadastroFluigRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true
})

const informacoesCandidatoHierarquiaCadastroFluigRequestSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false
})

const informacoesCandidatoHierarquiaCadastroFluigRequestFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const candidatesPasswordResetRequest = (state) => ({
  ...state,
  isLoading: true
})

const candidatesPasswordResetSuccess = (state, payload) => ({
  ...state,
  isLoading: false
})

const candidatesPasswordResetFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false
})

const releaseCancellationForCandidatesRequest = state => ({
  ...state,
  isLoading: true
});
const releaseCancellationForCandidatesSuccess = (state, payload) => ({
     ...state,
     isLoading: false,

     });
const releaseCancellationForCandidatesFail = (state, error) => ({
  ...state,
  isLoading: false,
  error
});

const candidateInformationRmSecaoListRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true,
});

const candidateInformationRmSecaoListSuccess = (state, candidate) => ({
  ...state,
  ...candidate,
  isLoading: false,
});

const candidateInformationRmSecaoListFail = (state, error) => ({
  ...state,
  ...error,
  isLoading: false,
});

const candidateDocumentUpdateRequest = state => ({
  ...state,
  isLoading: true
});

const candidateDocumentUpdateSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false
});

const candidateDocumentUpdateFail = (state, error) => ({
  ...state,
  error,
  isLoading: false
});

const candidateExportDocumentRequest = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: true,
});

const candidateExportDocumentSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});

const candidateExportDocumentFail = (state, error) => ({
  ...state,
  error,
  isLoading: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.VACANCY_CANDIDATES_REQUEST]: vacancyCandidatesRequest,
  [Types.VACANCY_CANDIDATES_REQUEST_SUCCESS]: vacancyCandidatesRequestSuccess,
  [Types.VACANCY_CANDIDATES_REQUEST_FAIL]: vacancyCandidatesRequestFail,

  [Types.CANDIDATES_FROM_REQUEST]: candidatesFromRequest,
  [Types.CANDIDATES_FROM_SUCCESS]: candidatesFromSuccess,
  [Types.CANDIDATES_FROM_FAIL]: candidatesFromRequestFail,

  [Types.CANDIDATES_MANAGER_REQUEST]: candidatesManagerRequest,
  [Types.CANDIDATES_MANAGER_SUCCESS]: candidatesManagerSuccess,
  [Types.CANDIDATES_MANAGER_FAIL]: candidatesManagerFail,

  [Types.CANDIDATES_DISPATCH_MANAGER_REQUEST]: candidatesDispatchManagerRequest,
  [Types.CANDIDATES_DISPATCH_MANAGER_SUCCESS]: candidatesDispatchManagerSuccess,
  [Types.CANDIDATES_DISPATCH_MANAGER_FAIL]: candidatesDispatchManagerFail,

  [Types.CANDIDATE_SHOW_REQUEST]: candidateShowRequest,
  [Types.CANDIDATE_SHOW_SUCCESS]: candidateShowSuccess,
  [Types.CANDIDATE_SHOW_FAIL]: candidateShowFail,
  [Types.CANDIDATES_SCHEDULE_ENTERVIEW_REQUEST]: candidatesScheduleEnterviewRequest,
  [Types.CANDIDATES_SCHEDULE_ENTERVIEW_SUCCESS]: candidatesScheduleEnterviewSuccess,
  [Types.CANDIDATES_SCHEDULE_ENTERVIEW_FAIL]: candidatesScheduleEnterviewFail,

  [Types.CANDIDATES_EXPORT_REQUEST]: candidatesExportRequest,
  [Types.CANDIDATES_EXPORT_SUCCESS]: candidatesExportSuccess,
  [Types.CANDIDATES_EXPORT_FAIL]: candidatesExportFail,

  [Types.CANDIDATES_ADD_CANDIDATE_REQUEST]: candidatesAddCandidateRequest,
  [Types.CANDIDATES_ADD_CANDIDATE_SUCCESS]: candidatesAddCandidateSuccess,
  [Types.CANDIDATES_ADD_CANDIDATE_FAIL]: candidatesAddCandidateFail,

  [Types.CANDIDATES_CREATE_CANDIDATE_REQUEST]: candidatesCreateCandidateRequest,
  [Types.CANDIDATES_CREATE_CANDIDATE_SUCCESS]: candidatesCreateCandidateSuccess,
  [Types.CANDIDATES_CREATE_CANDIDATE_FAIL]: candidatesCreateCandidateFail,

  [Types.CANDIDATES_CREATE_CANDIDATE_BULK_REQUEST]: candidatesCreateCandidateBulkRequest,
  [Types.CANDIDATES_CREATE_CANDIDATE_BULK_SUCCESS]: candidatesCreateCandidateBulkSuccess,
  [Types.CANDIDATES_CREATE_CANDIDATE_BULK_FAIL]: candidatesCreateCandidateBulkFail,


  [Types.CANDIDATES_GET_CANDIDATES_LIST_REQUEST]: candidatesGetCandidatesListRequest,
  [Types.CANDIDATES_GET_CANDIDATES_LIST_SUCCESS]: candidatesGetCandidatesListSuccess,
  [Types.CANDIDATES_GET_CANDIDATES_LIST_FAIL]: candidatesGetCandidatesListFail,


  [Types.INFORMACOES_CANDIDATO_REQUEST]:  informacoesCandidatoRequest,
  [Types.INFORMACOES_CANDIDATO_REQUEST_SUCCESS]: informacoesCandidatoRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_REQUEST_FAIL]: informacoesCandidatoRequestFail,


  [Types.CANDIDATE_PROFILE_UPDATE_REQUEST]: candidateProfileUpdateRequest,
  [Types.CANDIDATE_PROFILE_UPDATE_SUCCESS]: candidateProfileUpdateSuccess,
  [Types.CANDIDATE_PROFILE_UPDATE_FAIL]: candidateProfileUpdateFail,

  [Types.CANDIDATE_ADMISSION_REQUEST]: candidateAdmissionRequest,
  [Types.CANDIDATE_ADMISSION_SUCCESS]: candidateAdmissionSuccess,
  [Types.CANDIDATE_ADMISSION_FAIL]: candidateAdmissionFail,

  [Types.CANDIDATE_REGISTER_VACANCY_REQUEST]: candidateRegisterVacancyRequest,
  [Types.CANDIDATE_REGISTER_VACANCY_SUCCESS]: candidateRegisterVacancySuccess,
  [Types.CANDIDATE_REGISTER_VACANCY_FAIL]: candidateRegisterVacancyFail,

  [Types.CANDIDATE_SIMPLE_REQUEST]: candidateSimpleRequest,
  [Types.CANDIDATE_SIMPLE_SUCCESS]: candidateSimpleSuccess,
  [Types.CANDIDATE_SIMPLE_FAIL]: candidateSimpleFail,

  [Types.CANDIDATE_REQUIRE_FIELDS]: candidateRequireFields,
  [Types.CANDIDATE_UNREQUIRE_FIELDS]: candidateUnrequireFields,

  [Types.INFORMACOES_CANDIDATO_RM_TOMADOR_REQUEST]:  informacoesCandidatoRmTomadorRequest,
  [Types.INFORMACOES_CANDIDATO_RM_TOMADOR_REQUEST_SUCCESS]: informacoesCandidatoRmTomadorRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_RM_TOMADOR_REQUEST_FAIL]: informacoesCandidatoRmTomadorRequestFail,

  [Types.INFORMACOES_CANDIDATO_RM_TIPO_FUNCIONARIO_REQUEST]:  informacoesCandidatoRmTipoFuncionarioRequest,
  [Types.INFORMACOES_CANDIDATO_RM_TIPO_FUNCIONARIO_REQUEST_SUCCESS]: informacoesCandidatoRmTipoFuncionarioRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_RM_TIPO_FUNCIONARIO_REQUEST_FAIL]: informacoesCandidatoRmTipoFuncionarioRequestFail,

  [Types.INFORMACOES_CANDIDATO_RM_SECAO_REQUEST]: informacoesCandidatoRmSecaoRequest,
  [Types.INFORMACOES_CANDIDATO_RM_SECAO_REQUEST_SUCCESS]: informacoesCandidatoRmSecaoRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_RM_SECAO_REQUEST_FAIL]: informacoesCandidatoRmSecaoRequestFail,

  [Types.INFORMACOES_CANDIDATO_RM_FUNCAO_BY_COLIGADA_REQUEST]: informacoesCandidatoRmFuncaoByColigadaRequest,
  [Types.INFORMACOES_CANDIDATO_RM_FUNCAO_BY_COLIGADA_REQUEST_SUCCESS]: informacoesCandidatoRmFuncaoByColigadaRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_RM_FUNCAO_BY_COLIGADA_REQUEST_FAIL]: informacoesCandidatoRmFuncaoByColigadaRequestFail,

  [Types.INFORMACOES_CANDIDATO_COLIGADA_PERFIL_REQUEST]: informacoesCandidatoColigadaPerfilRequest,
  [Types.INFORMACOES_CANDIDATO_COLIGADA_PERFIL_REQUEST_SUCCESS]: informacoesCandidatoColigadaPerfilRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_COLIGADA_PERFIL_REQUEST_FAIL]: informacoesCandidatoColigadaPerfilRequestFail,

  [Types.INFORMACOES_CANDIDATO_COLIGADA_REQUEST]: informacoesCandidatoColigadaRequest,
  [Types.INFORMACOES_CANDIDATO_COLIGADA_REQUEST_SUCCESS]: informacoesCandidatoColigadaRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_COLIGADA_REQUEST_FAIL]: informacoesCandidatoColigadaRequestFail,

  [Types.INFORMACOES_CANDIDATO_MOTIVO_ADMISSAO_POR_COLIGADA_REQUEST]: informacoesCandidatoMotivoAdmissaoPorColigadaRequest,
  [Types.INFORMACOES_CANDIDATO_MOTIVO_ADMISSAO_POR_COLIGADA_REQUEST_SUCCESS]: informacoesCandidatoMotivoAdmissaoPorColigadaRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_MOTIVO_ADMISSAO_POR_COLIGADA_REQUEST_FAIL]: informacoesCandidatoMotivoAdmissaoPorColigadaRequestFail,

  [Types.INFORMACOES_CANDIDATO_SINDICATO_POR_COLIGADA_REQUEST]: informacoesCandidatoSindicatoPorColigadaRequest,
  [Types.INFORMACOES_CANDIDATO_SINDICATO_POR_COLIGADA_REQUEST_SUCCESS]: informacoesCandidatoSindicatoPorColigadaRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_SINDICATO_POR_COLIGADA_REQUEST_FAIL]: informacoesCandidatoSindicatoPorColigadaRequestFail,

  [Types.INFORMACOES_CANDIDATO_HORARIO_POR_COLIGADA_REQUEST]: informacoesCandidatoHorarioPorColigadaRequest,
  [Types.INFORMACOES_CANDIDATO_HORARIO_POR_COLIGADA_REQUEST_SUCCESS]: informacoesCandidatoHorarioPorColigadaRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_HORARIO_POR_COLIGADA_REQUEST_FAIL]: informacoesCandidatoHorarioPorColigadaRequestFail,

  [Types.INFORMACOES_CANDIDATO_PLANO_DENTAL_POR_COLIGADA_REQUEST]: informacoesCandidatoPlanoDentalPorColigadaRequest,
  [Types.INFORMACOES_CANDIDATO_PLANO_DENTAL_POR_COLIGADA_REQUEST_SUCCESS]: informacoesCandidatoPlanoDentalPorColigadaRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_PLANO_DENTAL_POR_COLIGADA_REQUEST_FAIL]: informacoesCandidatoPlanoDentalPorColigadaRequestFail,

  [Types.INFORMACOES_CANDIDATO_SEGURO_POR_COLIGADA_REQUEST]: informacoesCandidatoSeguroPorColigadaRequest,
  [Types.INFORMACOES_CANDIDATO_SEGURO_POR_COLIGADA_REQUEST_SUCCESS]: informacoesCandidatoSeguroPorColigadaRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_SEGURO_POR_COLIGADA_REQUEST_FAIL]: informacoesCandidatoSeguroPorColigadaRequestFail,

  [Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_POR_COLIGADA_REQUEST]: informacoesCandidatoAssistenciaMedicaPorColigadaRequest,
  [Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_POR_COLIGADA_REQUEST_SUCCESS]: informacoesCandidatoAssistenciaMedicaPorColigadaRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_POR_COLIGADA_REQUEST_FAIL]: informacoesCandidatoAssistenciaMedicaPorColigadaRequestFail,

  [Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_BRADESCO_POR_COLIGADA_REQUEST]: informacoesCandidatoAssistenciaMedicaBradescoPorColigadaRequest,
  [Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_BRADESCO_POR_COLIGADA_REQUEST_SUCCESS]: informacoesCandidatoAssistenciaMedicaBradescoPorColigadaRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_BRADESCO_POR_COLIGADA_REQUEST_FAIL]: informacoesCandidatoAssistenciaMedicaBradescoPorColigadaRequestFail,

  [Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_UNIMED_POR_COLIGADA_REQUEST]: informacoesCandidatoAssistenciaMedicaUnimedPorColigadaRequest,
  [Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_UNIMED_POR_COLIGADA_REQUEST_SUCCESS]: informacoesCandidatoAssistenciaMedicaUnimedPorColigadaRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_ASSISTENCIA_MEDICA_UNIMED_POR_COLIGADA_REQUEST_FAIL]: informacoesCandidatoAssistenciaMedicaUnimedPorColigadaRequestFail,

  [Types.INFORMACOES_CANDIDATO_PLANO_DENTAL_SULAMERICA_POR_COLIGADA_REQUEST]: informacoesCandidatoPlanoDentalSulamericaPorColigadaRequest,
  [Types.INFORMACOES_CANDIDATO_PLANO_DENTAL_SULAMERICA_POR_COLIGADA_REQUEST_SUCCESS]: informacoesCandidatoPlanoDentalSulamericaPorColigadaRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_PLANO_DENTAL_SULAMERICA_POR_COLIGADA_REQUEST_FAIL]: informacoesCandidatoPlanoDentalSulamericaPorColigadaRequestFail,

  [Types.INFORMACOES_CANDIDATO_REFEICAO_POR_COLIGADA_REQUEST]: informacoesCandidatoRefeicaoPorColigadaRequest,
  [Types.INFORMACOES_CANDIDATO_REFEICAO_POR_COLIGADA_REQUEST_SUCCESS]: informacoesCandidatoRefeicaoPorColigadaRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_REFEICAO_POR_COLIGADA_REQUEST_FAIL]: informacoesCandidatoRefeicaoPorColigadaRequestFail,

  [Types.INFORMACOES_CANDIDATO_ALIMENTACAO_POR_COLIGADA_REQUEST]: informacoesCandidatoAlimentacaoPorColigadaRequest,
  [Types.INFORMACOES_CANDIDATO_ALIMENTACAO_POR_COLIGADA_REQUEST_SUCCESS]: informacoesCandidatoAlimentacaoPorColigadaRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_ALIMENTACAO_POR_COLIGADA_REQUEST_FAIL]: informacoesCandidatoAlimentacaoPorColigadaRequestFail,


  [Types.INFORMACOES_CANDIDATO_HIERARQUIA_CADASTRO_FLUIG_REQUEST]: informacoesCandidatoHierarquiaCadastroFluigRequest,
  [Types.INFORMACOES_CANDIDATO_HIERARQUIA_CADASTRO_FLUIG_REQUEST_SUCCESS]: informacoesCandidatoHierarquiaCadastroFluigRequestSuccess,
  [Types.INFORMACOES_CANDIDATO_HIERARQUIA_CADASTRO_FLUIG_REQUEST_FAIL]: informacoesCandidatoHierarquiaCadastroFluigRequestFail,

  [Types.CANDIDATES_PASSWORD_RESET_REQUEST]: candidatesPasswordResetRequest,
  [Types.CANDIDATES_PASSWORD_RESET_SUCCESS]: candidatesPasswordResetSuccess,
  [Types.CANDIDATES_PASSWORD_RESET_FAIL]: candidatesPasswordResetFail,

  [Types.RELEASE_CANCELLATION_FOR_CANDIDATES_REQUEST]: releaseCancellationForCandidatesRequest,
  [Types.RELEASE_CANCELLATION_FOR_CANDIDATES_SUCCESS]: releaseCancellationForCandidatesSuccess,
  [Types.RELEASE_CANCELLATION_FOR_CANDIDATES_FAIL]: releaseCancellationForCandidatesFail,

  [Types.CANDIDATE_INFORMATION_RM_SECAO_LIST_REQUEST]: candidateInformationRmSecaoListRequest,
  [Types.CANDIDATE_INFORMATION_RM_SECAO_LIST_SUCCESS]: candidateInformationRmSecaoListSuccess,
  [Types.CANDIDATE_INFORMATION_RM_SECAO_LIST_FAIL]: candidateInformationRmSecaoListFail,

  [Types.CANDIDATE_DOCUMENT_UPDATE_REQUEST]: candidateDocumentUpdateRequest,
  [Types.CANDIDATE_DOCUMENT_UPDATE_SUCCESS]: candidateDocumentUpdateSuccess,
  [Types.CANDIDATE_DOCUMENT_UPDATE_FAIL]: candidateDocumentUpdateFail,

  [Types.CANDIDATE_EXPORT_DOCUMENT_REQUEST]: candidateExportDocumentRequest,
  [Types.CANDIDATE_EXPORT_DOCUMENT_SUCCESS]: candidateExportDocumentSuccess,
  [Types.CANDIDATE_EXPORT_DOCUMENT_FAIL]: candidateExportDocumentFail,
});

import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FormattedMessage } from "react-intl";
import RichTextEditor from "react-rte";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Switch from "react-switch";
import {
  ButtonDefaultStyle,
  ButtonSecondaryStyle,
  CheckboxContainer,
  customStylesSelect,
  ErrosTextIputValidation,
  HiddenCheckbox,
  HiddenRadio,
  Icon,
  Input,
  InputRow,
  InputRowCheckBox,
  InputSearch,
  InputSelectSearch,
  LabelInput,
  RadioIcon,
  SelectStyle,
  StyledCheckbox,
  StyledLink,
  StyledRadio,
  TextArea,
} from "./CustomInputsStyle";

export const ButtonDefault = (props) => (
  <ButtonDefaultStyle id="trigger" {...props}>
    {props.name}
  </ButtonDefaultStyle>
);

export const Button = (props) => {
  const { type, ...restProps } = props;

  if (type === "secondary") {
    return <ButtonSecondary {...restProps} />;
  }
  return <ButtonDefault {...restProps} />;
};
export const RichTextInput = ({
  label,
  onChange,
  validations,
  value,
  formErr,
  name,
  setFormErros,
  formValidators,
  touched,
  hasImage,
  disabled,
}) => {
  const [richTextState, setRichTextState] = useState(
    RichTextEditor.createEmptyValue()
  );
  const handleChange = (value) => {
    setRichTextState(value);
    if (
      value.toString("html") === "<p><br></p>" ||
      value.toString("html") === "<p>&nbsp;</p>"
    ) {
      onChange("");
    } else {
      onChange(value.toString("html"));
    }
  };
  const setShowError = () => {
    if (validations && !value) {
      const errors = validations.map((e) => e(value)).filter((k) => k !== null);
      if (!errors.length) {
        if (formErr) {
          delete formErr[name];
          setFormErros(formErr);
        }
      } else if (formErr) {
        formValidators(() => validations.map((e) => () => e(value)));
        formErr[name] = errors;
        setFormErros(formErr);
      }
    } else if (formErr) {
      delete formErr[name];
      setFormErros(formErr);
    }
  };
  useEffect(() => {
    if (value !== richTextState.toString("html")) {
      setRichTextState(
        RichTextEditor.createValueFromString(value || "", "html")
      );
    }

    setShowError();
  }, [value]);
  useEffect(() => {
    setShowError();
  }, [formErr]);

  const toolbarConfig = {
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      hasImage && "IMAGE_BUTTON",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
      { label: "Strikethrough", style: "STRIKETHROUGH" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };
  return (
    <InputRow width="100%">
      <LabelInput>{label}</LabelInput>
      <RichTextEditor
        onChange={(e) => handleChange(e)}
        editorStyle={{ height: "100px", width: "100%" }}
        value={richTextState}
        style={{ width: "100%" }}
        toolbarConfig={toolbarConfig}
        disabled={disabled}
      />
      <ErrosTextIputValidation>
        {formErr && formErr[name] && touched && !value
          ? formErr[name]
          : undefined}
      </ErrosTextIputValidation>
    </InputRow>
  );
};

export const ButtonSecondary = (props) => (
  <ButtonSecondaryStyle {...props}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {props.img && props.img({ width: "18px", height: "17px" })}
      <span>{props.name}</span>
    </div>
  </ButtonSecondaryStyle>
);

export const TextField = (props) => {
  const setShowError = () => {
    if (props.validations && !props.value) {
      const errors = props.validations
        .map((e) => e(props.value))
        .filter((k) => k !== null);
      if (!errors.length) {
        if (props.formErr) {
          delete props.formErr[props.name];
          props.setFormErros(props.formErr);
        }
      } else if (props.formErr) {
        props.formValidators(() =>
          props.validations.map((e) => () => e(props.value))
        );
        const { formErr } = props;
        formErr[props.name] = errors;
        props.setFormErros(formErr);
      }
    } else if (props.formErr) {
      delete props.formErr[props.name];
      props.setFormErros(props.formErr);
    }
  };
  useEffect(() => {
    setShowError();
  }, [props.formErr]);
  useEffect(() => {
    setShowError();
  }, [props.value]);
  return (
    <InputRow {...props.containerProps}>
      {props.label && <LabelInput>{props.label}</LabelInput>}
      <Input
        onBlur={() => setShowError()}
        onBlurCapture={() => setShowError()}
        {...props}
      />
      <ErrosTextIputValidation>
        {props.formErr &&
        props.formErr[props.name] &&
        props.touched &&
        !props.value
          ? props.formErr[props.name]
          : undefined}
      </ErrosTextIputValidation>
    </InputRow>
  );
};
export const TextAreaField = (props) => {
  const setShowError = () => {
    if (props.validations && !props.value) {
      const errors = props.validations
        .map((e) => e(props.value))
        .filter((k) => k !== null);
      if (!errors.length) {
        if (props.formErr) {
          delete props.formErr[props.name];
          props.setFormErros(props.formErr);
        }
      } else if (props.formErr) {
        props.formValidators(() =>
          props.validations.map((e) => () => e(props.value))
        );
        const { formErr } = props;
        formErr[props.name] = errors;
        props.setFormErros(formErr);
      }
    } else if (props.formErr) {
      delete props.formErr[props.name];
      props.setFormErros(props.formErr);
    }
  };
  useEffect(() => {
    setShowError();
  }, [props.formErr]);
  useEffect(() => {
    setShowError();
  }, [props.value]);
  return (
    <InputRow>
      <LabelInput>{props.label}</LabelInput>
      <TextArea onBlur={() => setShowError()} {...props} />
      <ErrosTextIputValidation>
        {props.formErr &&
        props.formErr[props.name] &&
        props.touched &&
        !props.value
          ? props.formErr[props.name]
          : undefined}
      </ErrosTextIputValidation>
    </InputRow>
  );
};
export const SelectFieldInput = (props) => {
  const setShowError = () => {
    if (props.validations && !props.value) {
      const errors = props.validations
        .map((e) => e(props.value))
        .filter((k) => k !== null);
      if (!errors.length) {
        if (props.formErr) {
          delete props.formErr[props.name];
          props.setFormErros(props.formErr);
        }
      } else if (props.formErr) {
        props.formValidators(() =>
          props.validations.map((e) => () => e(props.value))
        );
        const { formErr } = props;
        formErr[props.name] = errors;
        props.setFormErros(formErr);
      }
    } else if (props.formErr) {
      delete props.formErr[props.name];
      props.setFormErros(props.formErr);
    }
  };
  useEffect(() => {
    setShowError();
  }, [props.formErr]);
  useEffect(() => {
    setShowError();
  }, [props.value]);

  const renderDefaultOption = () => {
    if (props.DefaultOption)
      return <option value="">{props.DefaultOption}</option>;

    if (!props.skipDefaultOption)
      return <option value="">Selecionar...</option>;
  };

  return (
    <InputRow width={props.width}>
      {props.label && <LabelInput ml={props.ml}>{props.label}</LabelInput>}
      <SelectStyle onBlur={() => setShowError()} {...props}>
        {renderDefaultOption()}
        {Array.isArray(props.items) &&
          props.items
            .filter((e) => e.disabled !== true)
            .map((k) => (
              <option key={k.id} value={k.id} disabled={k.disabled}>
                {k.nome || k.funcao || k.descricao || k.resumo || k.name}
              </option>
            ))}
      </SelectStyle>
      <ErrosTextIputValidation>
        {props.formErr &&
        props.formErr[props.name] &&
        props.touched &&
        !props.value
          ? props.formErr[props.name]
          : undefined}
      </ErrosTextIputValidation>
    </InputRow>
  );
};
export const MultSelect = (props) => {
  const [error, setError] = useState("");
  const setShowError = () => {
    if (props.validations && (!props.value || !props.value.length)) {
      const errors = props.validations.map((e) => e(props.value));
      setError(errors);
      if (props.formErr && (!props.value || !props.value.length)) {
        props.formValidators(() =>
          props.validations.map((e) => () => e(props.value))
        );
        const { formErr } = props;
        formErr[props.name] = errors;
        props.setFormErros(formErr);
      }
    } else if (props.value) {
      const { formErr } = props;
      try {
        delete formErr[props.name];
        props.setFormErros(formErr);
      } catch (error) {}
    }
  };
  useEffect(() => {
    setShowError();
  }, [props.formErr, props.value]);
  return (
    <InputRow mr={props.mr} ml={props.ml} width={props.width}>
      {props.label && <LabelInput ml={props.ml}>{props.label}</LabelInput>}
      <Select placeholder="Selecionar" styles={customStylesSelect} {...props} />
      <ErrosTextIputValidation>
        {props.formErr &&
        error &&
        props.formErr[props.name] &&
        props.touched &&
        (!props.value || !props.value.length)
          ? props.formErr[props.name]
          : undefined}
      </ErrosTextIputValidation>
    </InputRow>
  );
};

export const MultSelectCreatable = (props) => {
  const [error, setError] = useState("");
  const setShowError = () => {
    if (props.validations && (!props.value || !props.value.length)) {
      const errors = props.validations.map((e) => e(props.value));
      setError(errors);
      if (props.formErr && (!props.value || !props.value.length)) {
        props.formValidators(() =>
          props.validations.map((e) => () => e(props.value))
        );
        const { formErr } = props;
        formErr[props.name] = errors;
        props.setFormErros(formErr);
      }
    } else if (props.value) {
      const { formErr } = props;
      try {
        delete formErr[props.name];
        props.setFormErros(formErr);
      } catch (error) {}
    }
  };
  useEffect(() => {
    setShowError();
  }, [props.formErr, props.value]);
  return (
    <InputRow mr={props.mr} ml={props.ml} width={props.width}>
      {props.label && <LabelInput ml={props.ml}>{props.label}</LabelInput>}
      <CreatableSelect
        placeholder="Digite..."
        styles={customStylesSelect}
        {...props}
      />
      <ErrosTextIputValidation>
        {props.formErr &&
        error &&
        props.formErr[props.name] &&
        props.touched &&
        (!props.value || !props.value.length)
          ? props.formErr[props.name]
          : undefined}
      </ErrosTextIputValidation>
    </InputRow>
  );
};

export const CustomFieldSearch = ({ ...props }) => <InputSearch {...props} />;

export const CustomFieldSelectSearch = ({ ...props }) => (
  <InputSelectSearch {...props}>
    <option value={""}>
      {props.formattedMessage ? (
        <FormattedMessage id="inputs.select" />
      ) : (
        "Selecionar..."
      )}
    </option>
    {props.items &&
      props.items.map((e) => (
        <option value={e.id}>{e.descricao || e.nome}</option>
      ))}
  </InputSelectSearch>
);

export const CheckBox = ({ ...props }) => {
  const [checked, setChecked] = useState(props.checked);

  const callAction = () => {
    if (!props.disabled) {
      setChecked(!checked);
      if (props.onChange) {
        props.onChange();
      }
    }
  };

  return (
    <CheckboxContainer>
      <HiddenCheckbox />
      <InputRowCheckBox>
        <StyledCheckbox
          group={props.group}
          checked={props.checked}
          onClick={() => callAction()}
          radius={props.radius}
          type="checkbox"
        >
          {/*
          <Icon>
            <polyline points="15 6 9 17 4 12" />
          </Icon>
           */}
          <div
            style={{
              width: "23px ",
              height: "23px",
              display: `${props.checked ? "block" : "none"}`,
            }}
          >
            <Icon>
              <polyline points="15 6 9 17 4 12" />
            </Icon>
          </div>
        </StyledCheckbox>
        <LabelInput noMarginBottom>{props.label}</LabelInput>
      </InputRowCheckBox>
    </CheckboxContainer>
  );
};

export const SwitchInput = (props) => (
  <Switch
    width={34}
    height={14}
    handleDiameter={20}
    uncheckedIcon={false}
    onColor="#17b3d2"
    offHandleColor="#bdbdbd"
    onHandleColor="#17b3d2"
    checkedIcon={false}
    offColor="#ededed"
    {...props}
  />
);

export const Link = (props) => <StyledLink {...props} />;

export const RadioInput = (props) => {
  const callAction = (e) => {
    if (props.onChange) {
      props.onChange(e);
    }
  };
  const renderIcon = () => {
    let opacity = 0;
    if (props.selectedOption == props.value) {
      opacity = 1;
    }
    return <RadioIcon disabled={props.disabled} opacity={opacity} />;
  };
  
  return (
    <CheckboxContainer mt={props.mt} mb={props.mb}>
      <InputRowCheckBox>
        <Col
          xs={props.colCheckBox}
          sm={props.colCheckBox}
          md={props.colCheckBox}
          lg={props.colCheckBox}
          xl={props.colCheckBox}
          style={{ padding: "0px" }}
        >
          <StyledRadio
            group={props.group}
            disabled={props.disabled}
            type="radio"
            checked={props.selectedOption === props.value}
          >
            <HiddenRadio
              value={props.value}
              disabled={props.disabled}
              onChange={(e) => callAction(e)}
              checked={props.selectedOption === props.value}
            />
            {renderIcon()}
          </StyledRadio>
        </Col>
        <Col
          xs={props.colLabel}
          sm={props.colLabel}
          md={props.colLabel}
          lg={props.colLabel}
          xl={props.colLabel}
          style={{ padding: "0px" }}
        >
          <LabelInput
            size={props.size}
            color={
              props.selectedOption !== props.value ? props.color : "darkGreen"
            }
            style={
              props.selectedOption === props.value ? { fontWeight: "bold" } : {}
            }
            noMarginBottom
          >
            {props.label}
          </LabelInput>
        </Col>
      </InputRowCheckBox>
    </CheckboxContainer>
  );
};

export const DatePicker = (props) => {
  const setShowError = () => {
    if (props.validations && !props.value) {
      const errors = props.validations
        .map((e) => e(props.value))
        .filter((k) => k !== null);
      if (!errors.length) {
        if (props.formErr) {
          delete props.formErr[props.name];
          props.setFormErros(props.formErr);
        }
      } else if (props.formErr) {
        props.formValidators(() =>
          props.validations.map((e) => () => e(props.value))
        );
        const { formErr } = props;
        formErr[props.name] = errors;
        props.setFormErros(formErr);
      }
    } else if (props.formErr) {
      delete props.formErr[props.name];
      props.setFormErros(props.formErr);
    }
  };
  useEffect(() => {
    setShowError();
  }, [props.formErr]);
  useEffect(() => {
    setShowError();
  }, [props.value]);

  return (
    <InputRow {...props.containerProps}>
      {props.label && !props.hidden && (
        <LabelInput ml={props.ml}>{props.label}</LabelInput>
      )}
      <Form.Control
        type="date"
        onBlur={() => setShowError()}
        onBlurCapture={() => setShowError()}
        {...props}
        style={{
          borderRadius: 2,
          boxShadow: "none",
          borderColor: "#A5A5A5",
          color: "#A5A5A5",
        }}
      />
      <ErrosTextIputValidation>
        {props.formErr &&
        props.formErr[props.name] &&
        props.touched &&
        !props.value
          ? props.formErr[props.name]
          : undefined}
      </ErrosTextIputValidation>
    </InputRow>
  );
};

export const SelectInput = (props) => {
  const id = `select_${new Date().getTime()}`;
  return (
    <InputRow
      id={id}
      mb={props.mb}
      mr={props.mr}
      ml={props.ml}
      width={props.width}
    >
      <LabelInput ml={props.ml}>{props.label}</LabelInput>
      <Select
        placeholder={props.placeholder ? props.placeholder : ""}
        styles={{
          ...customStylesSelect,
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        isDisabled={props.isDisabled}
        {...props}
        menuPortalTarget={document.body.querySelector(`#${id}`)}
      />
    </InputRow>
  );
};

import { Body, FlexAction, Span, SubmitRow } from "./UserStyle";
import {
  ButtonDefault,
  ButtonSecondary,
  SelectFieldInput,
  MultSelect,
  SelectInput,
  TextField,
  CheckBox
} from "components/shared/CustomInputs";
import React, { useEffect, useState, PureComponent } from "react";
import FormGroup from "../../../../components/Forms/group/FormGroup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Col, Modal, Row } from "react-bootstrap";
import { required } from "../../../../helpers/validations";
import { Creators as managerActions } from "store/ducks/manager";
import { Creators as employeeAction } from "store/ducks/employee";
import { Creators as messageAction } from "store/ducks/messages";
import { FormattedMessage, injectIntl } from "react-intl";
import { Creators as candidateActions } from "../../../../store/ducks/candidates.js";
import { Creators as formInputsActions } from "store/ducks/formInputs";
import { Redirect } from "react-router-dom";
import {
  formatErrorsMessages
} from "../../../../helpers/sharedHelpers";

const Users = ({
  getProfilesList,
  getEmployee,
  employeeData,
  messagePublication,
  profilesList,
  intl,
  users,
  ProjetoListAll,
  informationRmSecaoListRequest,
  informacoesCandidatoColigadaRequest,
  coligadaCandidato,
}) => {

  const [formValues, setFormValues] = useState({
    profileId: 0,
    registrationNumber: '',
    name: '',
    email: '',
    profile: '',
    pessoaId: 0,
    isAdministrador: false,
    canAcessPlug: false,
    canAcessTalentos: false,
    projetoId: [],
    coligadaCandidato: [],
  });

  const [filteredColigadaOptions, setFilteredColigadaOptions] = useState([]);
  const [formErros, setFormErros] = useState([]);
  const [formValidators, setFormValidators] = useState();
  const [submited, setSubmited] = useState(false);
  const [profiles, setProfiles] = useState(new Array());
  const [employee, setEmployee] = useState({});
  const [foundEmployee, setFoundEmployee] = useState(false);
  const [codSecaoOptions, setCodSecaoOptions] = useState([]);
  const [profilesListOptions, setProfilesListOptions] = useState([]);

  useEffect(() => {
    getProfilesList();
    getEmployee('');
  }, []);



  useEffect(() => {
    if (profilesList) {
      const options = profilesList.map((e) => ({
        value: e.id,
        label: e.nome,
      }));
      setProfilesListOptions(options);
    }
  }, [profilesList]);



  useEffect(() => {
    setEmployee(employeeData["employee"]);
    if (!isEmpty(employee)) {
      setFoundEmployee(true);
      setFormValues({ ...formValues, name: employee.nome, email: employee.email, pessoaId: employee.pessoa.id });
    }
    else {
      setFoundEmployee(false);
      setFormValues({ ...formValues, name: '', email: '' });
    }
  }, [employeeData, employee]);

  function isEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop))
        return false;
    }

    return true;
  }
  const renderSaveButtonTitle = () => {
    return <FormattedMessage id="sharedItems.save" />;
  };
  const renderSearchButtonTitle = () => {
    return <FormattedMessage id="manager.search" />;
  };

  const renderBodyTitle = () => {
    return <FormattedMessage id="manager.newUser" />;
  };
  const changeName = (name) => {
    if (formValues.name && formValues.name != name) {
      setFormValues({
        ...formValues,
        name: name,
        pessoaId: 0
      })
    }
    else {
      setFormValues({
        ...formValues,
        name: name
      })
    }
  }
  const save = () => {
    setSubmited(true);
    if (!Object.keys(formErros).length) {
      saveValidEmployee();
    } else {
      const formErrosMessages = formatErrorsMessages(formErros);
      messagePublication(formErrosMessages);
    }
  }

  useEffect(() => {
    if (ProjetoListAll && ProjetoListAll.length > 0) {
      const options = ProjetoListAll.map((e) => ({
        value: e.id,
        label: e.nome,
      }));
      setCodSecaoOptions(options);
    }
  }, [ProjetoListAll]);


  useEffect(() => {
    informacoesCandidatoColigadaRequest();
  }, []);


  useEffect(() => {
    if (formValues.coligadaCandidato) {
      informationRmSecaoListRequest(formValues.coligadaCandidato);
    }
  }, [formValues.coligadaCandidato]);


  const saveValidEmployee = () => {
    users({
      nome: formValues.name,
      email: formValues.email,
      pessoa_Id: 0,
      perfilAcesso_Id: formValues.profileId,
      podeEditarPerfil: formValues.isAdministrador ? 1 : 0,
      podeAcessarPlug: formValues.canAcessPlug ? 1 : 0,
      podeAcessarTalentos: formValues.canAcessTalentos ? 1 : 0,
      projetoId: formValues.projetoId,
      colidagaId: formValues.coligadaCandidato,
    });
  };

  return (
    <React.Fragment>
      <Body>
        <FormGroup formName={renderBodyTitle()}>
          <Row>
            <Col
              md={6}>
              <TextField
                onChange={event => changeName(event.target.value)}
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                touched={submited}
                formErr={formErros}
                name={intl.formatMessage({ id: "manager.name" })}
                label={<FormattedMessage id="manager.name" />}
                value={formValues.name}
              />
            </Col>
            <Col
              md={6}>
              <TextField
                onChange={event =>
                  setFormValues({
                    ...formValues,
                    email: event.target.value
                  })
                }
                setFormErros={setFormErros}
                formValidators={setFormValidators}
                touched={submited}
                formErr={formErros}
                name={intl.formatMessage({ id: "manager.email" })}
                label={<FormattedMessage id="manager.email" />}
                value={formValues.email}
              />
            </Col>
          </Row>
          <Row>
            <Col md={4}>
            <SelectInput
              options={profilesListOptions}
              value={
                profilesListOptions?.find(
                  (e) => e.value === formValues.profileId
                ) || ""
              }
              onChange={(e) => {
                const newProfileId = e.value;

                setFormValues({
                  ...formValues,
                  profileId: newProfileId,
                  coligadaCandidato: [],
                  projetoId: [],
                });
              }}
              label={"Perfil"}
            />
          </Col>
            <Col lg={4}>
            <MultSelect
              mb="1"
              options={coligadaCandidato.map((e) => ({
                value: e.id,
                label: e.nome,
              }))}
              onChange={(selectedOptions) => {
                const newValues = selectedOptions
                  ? selectedOptions.map((option) => option.value)
                  : [];

                if (formValues.coligadaCandidato.length > newValues.length) {
                  setFormValues((prevState) => ({
                    ...prevState,
                    coligadaCandidato: newValues,
                    projetoId: [],
                  }));
                } else {
                  setFormValues({ ...formValues, coligadaCandidato: newValues });
                }
              }}
              value={coligadaCandidato.filter((e) =>
                  formValues.coligadaCandidato.includes(e.id)
                )
                .map((e) => ({
                  value: e.id,
                  label: e.nome,
                }))}
              isMulti
              label={<FormattedMessage id="sharedItems.operation" />}
            />
          </Col>
            <Col md={3}>
              <CheckBox
                label={<FormattedMessage id="manager.IsAdministrator" />}
                checked={formValues.isAdministrador}
                onChange={() =>
                  setFormValues({
                    ...formValues,
                    isAdministrador: !formValues.isAdministrador
                  })
                }
              />
            </Col>
            {localStorage.getItem("tipoPerfilUsuario") == 1 && (
              <Col md={3}>
              <CheckBox
                label={<FormattedMessage id="manager.canAcessTalentos" />}
                checked={formValues.canAcessTalentos}
                onChange={() =>
                  setFormValues({
                    ...formValues,
                    canAcessTalentos: !formValues.canAcessTalentos
                  })
                }
              />
            </Col>
            )}
            <Col md={3}>
              <CheckBox
                label={<FormattedMessage id="manager.canAcessPlug" />}
                checked={formValues.canAcessPlug}
                onChange={() =>
                  setFormValues({
                    ...formValues,
                    canAcessPlug: !formValues.canAcessPlug
                  })
                }
              />
            </Col>
          </Row>
          <SubmitRow className="row">
            <div style={{ padding: "5px" }} className="col-lg-2">
              <FlexAction>
                <ButtonDefault
                  onClick={() => {
                    save();
                  }}

                  name={renderSaveButtonTitle()}
                />
              </FlexAction>
            </div>
          </SubmitRow>
        </FormGroup>
      </Body>
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  profilesList: state.profiles.profiles,
  employeeData: state.employee,
  ProjetoListAll: state.profiles.informationSecao,
  coligadaCandidato: state.candidates.coligadaCandidato,
});

const mapDispatchToProps = dispatch => ({
  getProfilesList: payload => dispatch(managerActions.profilesRequest(payload)),
  getEmployee: registerNumber =>
    dispatch(employeeAction.employeeSearchRequest(registerNumber)),
  messagePublication: messages =>
    dispatch(messageAction.messagePublication(messages)),
  users: payload => dispatch(managerActions.usersRequest(payload)),
  informationRmSecaoListRequest: (payload) =>
    dispatch(managerActions.informationRmSecaoListRequest(payload)),
  informacoesCandidatoColigadaRequest: () =>
    dispatch(candidateActions.informacoesCandidatoColigadaPerfilRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Users));


